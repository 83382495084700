import { useState, useEffect } from "react";
import { NavBar, Mask, SpinLoading, Footer } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { markAsRead } from "../../../service/me/notice";
import styles from "./index.module.css";

export default function NoticeDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(true); // 控制遮罩层显示与隐藏
  const { item } = location.state || {}; // 获取传递的数据

  useEffect(() => {
    const markReadStatus = async () => {
      if (item && item.is_read === 0) {
        try {
          await markAsRead(item.member_broadcast_id); // 调用标记为已读的接口
          // console.log("通知已成功标记为已读"); // 成功标记为已读后的日志
        } catch (error) {
          console.error("标记为已读失败:", error); // 错误处理
        } finally {
          setVisible(false); // 隐藏遮罩层
        }
      } else {
        setVisible(false); // 如果已经是已读，直接隐藏遮罩层
      }
    };

    markReadStatus();
  }, [item]);

  return (
    <>
      <NavBar onBack={() => navigate(-1)}>消息通知</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <div className={styles.detail_info}>
        <h1>
          {item.title}
        </h1>
        <p className={styles.detail_time}>
          {new Date(item.broadcast_time).toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric' })}
        </p>
        <div className={styles.detail_content} dangerouslySetInnerHTML={{ __html: item.info }} />
      </div>
      <Footer style={{ padding: "1rem 0 3rem 0" }} content="~ 到底了 ~" />
    </>
  );
}
