import React, { useState, createContext, useContext } from "react";
import { InsAudit, reqAlreadyLogin, reqVerfiyPark } from "./service/home/login";
import { reqLogin, reqParkId } from "./service/home/login";
import { LoginOut } from "./service/home/login";
import { useLocation, useNavigate } from "react-router-dom";
import { STATUS } from "./constants";
import MyMask from "./components/MyMask";
import MySpin from "./components/MySpin";
import { reqPark } from "./service/home/login";
const AuthContext = createContext();

function useAuth() {
  const [authed, setAuthed] = useState(STATUS.idle);
  const [role, setRole] = useState();
  const location = useLocation();
  const { pathname } = location;
  const parkName = location?.pathname.split("/").filter(Boolean)[0];
  const navigate = useNavigate();
  return {
    authed,
    role,
    async fetchRole(parkname = parkName) {
      return await reqPark(parkname).then((res) => {
        if (res.data.length > 0) {
          setRole(res.data[0].role);
        }
      });
    },
    async login(values, setStatus, setLoading, setTip) {
      return await reqLogin(values)
        .then((res) => {
          setStatus(STATUS.success);
          setLoading(false);
          return reqParkId(values.username, parkName);
        })
        .then((resParkId) => {
          if (resParkId.data.length === 0) {
            setStatus(STATUS.failure);
            return;
          }
          if (resParkId.data[0].short_name !== parkName) {
            setStatus(STATUS.failure);
            setTip("园区内无此账号");
            LoginOut();
            return;
          }
          setAuthed(STATUS.success);
          InsAudit(resParkId.data[0].id, 1, resParkId.data[0].park_id);
          if (location.state !== null) {
            navigate(pathname, { replace: true });
          } else {
            navigate(`/${location.pathname.split("/").filter(Boolean)[0]}/me`, {
              replace: true,
            });
          }
        })
        .catch((error) => {
          console.log("error :>> ", error);
          setStatus(STATUS.failure);
          setLoading(false);
        });
    },
    async fetchAlreadyLogins() {
      return await reqAlreadyLogin()
        .then((res) => {
          if (res.data.user === null) {
            setAuthed(STATUS.failure);
            return Promise.reject("cuowu");
          }
          return res;
        })
        .then((res) => {
          reqVerfiyPark(res.data.user?.email, parkName).then((res) => {
            if (res.data.length === 0) {
              setAuthed(STATUS.failure);
              return;
            }
            setAuthed(STATUS.success);
          });
        })
        .catch((error) => {});
    },
    async logout() {
      return await LoginOut().then((res) => {
        setAuthed(STATUS.failure);
      });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();
  const { fetchAlreadyLogins, authed, role, fetchRole } = auth;
  if (authed === STATUS.idle) {
    fetchAlreadyLogins();
  }
  if (role === undefined) {
    fetchRole();
  }
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
  return useContext(AuthContext);
}

export function RequireAuth({ children }) {
  const { authed } = AuthConsumer();

  return (
    <>
      {authed === STATUS.idle && <MySpin />}
      {authed === STATUS.success && children}
      {authed === STATUS.failure && <MyMask />}
    </>
  );
}
export function RequireRole({ children }) {
  const { role } = AuthConsumer();
  return <>{role === 1 && children}</>;
}
