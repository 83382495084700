import styles from "./register.module.css";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import {
  Form,
  Input,
  Button,
  Footer,
  NavBar,
  SearchBar,
  Popup,
  CheckList,
  InfiniteScroll,
  Checkbox,
  Toast,
} from "antd-mobile";

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { reqCompanies } from "../service/company";
import { reqPark } from "../service/home/login";
import { reqRegister } from "../service/register";
import { DemoreqInsertUser } from "../api/demo";
import qs from "query-string";
export default function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const [loading, setLoading] = useState(false);
  const parkName = location.pathname.split("/").filter(Boolean)[0];
  const [park, setPark] = useState();
  const [form] = Form.useForm();
  const [isSel, setSel] = useState(true);

  const [visible, setVisible] = useState(false);
  const [popupVisible1, setPopupVisible1] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [keywords, setKeywords] = useState("");
  const [checkboxValue, setCheckBoxValue] = useState(true);

  // 加载公司列表
  const loadMore = async (parkId, page, keywords) => {
    const append = await reqCompanies(parkId, page, keywords);
    setCompanies((val) => [...val, ...append.data]);
    setHasMore(append.data.length > 0);
    setPage(page + 1);
  };
  // 选择公司
  const checkListOnChange = async (value) => {
    if (value.length > 0) {
      const filterCom = companies.filter(
        (item) => item.format_name === value.toString()
      );
      form.setFieldValue("com_name", filterCom[0].format_name);
      form.setFieldValue("company_id", filterCom[0].id);
      form.validateFields();
    } else {
      form.setFieldValue("com_name", "");
      form.setFieldValue("company_id", "");
      form.validateFields();
    }
  };
  // 更改勾选状态
  const onCheckChange = (value) => {
    setCheckBoxValue(!checkboxValue);
  };
  // 改变搜索关键词
  const searchBarOnChange = async (value) => {
    setKeywords(value);
  };
  // 发送搜索信息
  const searchBarOnSearch = async () => {
    setCompanies([]);
    loadMore(park.id, 1, keywords);
  };
  // 清空搜索列表
  const searchBarOnClear = async (value) => {
    setKeywords("");
    setCompanies([]);
    loadMore(park.id, 1);
  };
  // 正则是否含有空格
  const validateSpace = (rule, value) => {
    // 使用正则表达式检查输入的值是否包含空格
    if (/\s/.test(value)) {
      return Promise.reject(new Error("姓名不能含有空格!"));
    } else {
      return Promise.resolve();
    }
  };

  // 提交注册
  const onFinish = (values) => {
    Toast.show({
      icon: "loading",
      duration: 0,
    });
    setLoading(true);

    const doPost = async () => {
      await DemoreqInsertUser({
        ...values,
        short_name: parkName,
        park_id: park.id,
      })
        .then((res) => {
          if (res && res.details) {
            setLoading(false);
            return Promise.reject("用户存在");
          }
        })
        .then(() => {
          reqRegister(values, parkName, window.location.host).then((res) => {
            Toast.clear();
            if (res.error === null) {
              navigate(`/${parkName}/register/mailbox?email=${values.email}`);
            } else {
              Toast.show({
                icon: "fail",
                content: "注册失败,请稍后再试",
              });
            }
          });
        })
        .catch((error) => {
          Toast.show({
            icon: "fail",
            content: "用户已存在",
          });
        });
    };
    doPost();
  };

  useEffect(() => {
    if (parkName) {
      reqPark(parkName).then((res) => {
        if (res.data.length > 0) {
          setPark(res.data[0]);
        }
      });
    }
    if (search) {
      const { company_id, com_name } = qs.parse(search);
      if (company_id && com_name) {
        setSel(false);
        form.setFieldValue("company_id", company_id);
        form.setFieldValue("com_name", com_name);
      }
    }
  }, [parkName, search]);

  return (
    <div className={styles.root}>
      <NavBar onBack={() => navigate(`/${parkName}/home`)}>用户注册</NavBar>
      <div className={styles.header}>
        <img src={park && park.img_url} alt="封面图" />
      </div>
      <Form
        onFinish={onFinish}
        form={form}
        footer={
          <>
            <Button
              disabled={loading || !checkboxValue}
              type="submit"
              color="primary"
              block
            >
              立即注册
            </Button>
            <Link className={styles.link} to={`/${parkName}/login`}>
              <span>已经注册了，去登录</span>
            </Link>
          </>
        }
      >
        <Form.Header>注册信息</Form.Header>
        {/* email */}
        <Form.Item
          name="email"
          label={<span>邮箱</span>}
          rules={[{ required: true, message: "邮箱地址不能为空" }]}
        >
          <Input type="email" placeholder="请输入邮箱" />
        </Form.Item>
        {/* password */}
        <Form.Item
          name="password"
          label={<span>密码</span>}
          rules={[{ required: true, message: "密码不能为空" }]}
        >
          <div className={styles.password}>
            <Input
              type={visible ? "text" : "password"}
              placeholder="请输入密码"
            />
            <div className={styles.toggle}>
              {!visible ? (
                <EyeInvisibleOutline onClick={() => setVisible(true)} />
              ) : (
                <EyeOutline onClick={() => setVisible(false)} />
              )}
            </div>
          </div>
        </Form.Item>
        {/* name */}
        <Form.Item
          name="name"
          label={<span>姓名</span>}
          rules={[
            { required: true, message: "用户名不能为空" },
            { validator: validateSpace },
          ]}
        >
          <Input placeholder="请输入姓名" />
        </Form.Item>
        {/* tel */}
        <Form.Item
          name="tel"
          label="手机"
          rules={[
            { required: true, message: "手机号码不能为空" },
            {
              pattern: /^1[3|415178][0-9]{9}$/, // 使用正则表达式验证手机号码格式
              message: "手机号码格式不正确",
            },
          ]}
        >
          <Input placeholder="请输入手机号码" />
        </Form.Item>
        {/* 公司 */}
        <Form.Item
          label="公司"
          name="com_name"
          disabled={!isSel}
          onClick={() => setPopupVisible1(true)}
          rules={[{ required: true, message: "公司不能为空" }]}
        >
          <Input placeholder="请选择公司" readOnly={true} />
        </Form.Item>
        <Form.Item hidden={true} label="公司id" name="company_id">
          <Input type="text" readOnly={true} />
        </Form.Item>
        {/* 职务 */}
        <Form.Item
          name="duty"
          label="职务"
          rules={[{ required: true, message: "职位不能为空" }]}
        >
          <Input placeholder="请输入职务" />
        </Form.Item>
        <Form.Item>
          <Checkbox checked={checkboxValue} onChange={onCheckChange}>
            已阅读并同意遵守<Link to="#">《服务协议》</Link>
          </Checkbox>
        </Form.Item>
      </Form>
      <Popup
        visible={popupVisible1}
        position="right"
        bodyStyle={{ width: "80vw" }}
        onMaskClick={() => setPopupVisible1(false)}
      >
        <div className={styles.popup}>
          <NavBar
            backArrow={false}
            back={<div className={styles.popupBtn}>关闭</div>}
            onBack={() => setPopupVisible1(false)}
            right={
              <div
                onClick={() => setPopupVisible1(false)}
                className={`${styles.popupBtn} ${styles.primary}`}
              >
                确认
              </div>
            }
          >
            选择企业
          </NavBar>
          <div className={styles.search}>
            <SearchBar
              placeholder="公司名称"
              clearable
              value={keywords}
              onSearch={(value) => searchBarOnSearch(value)}
              onClear={(value) => searchBarOnClear(value)}
              onChange={(value) => searchBarOnChange(value)}
            />
          </div>
          <CheckList
            // defaultValue={[`${state.format_name ?? ""}`]}
            onChange={(value) => checkListOnChange(value)}
          >
            {companies.map((item, index) => (
              <CheckList.Item key={index} value={item.format_name}>
                {item.format_name}
              </CheckList.Item>
            ))}
          </CheckList>
          <InfiniteScroll
            loadMore={() => loadMore(park.id, page, keywords)}
            hasMore={hasMore}
          />
        </div>
      </Popup>
      <Footer content={`© 2023 ${park && park.name}，由环球互通提供技术支持`} />
    </div>
  );
}
