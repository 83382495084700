import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Blog1.module.css";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
export default function Blog1({ post }) {
  dayjs.extend(utc);
  const { pathname } = useLocation();
  const parkName = pathname.split("/").filter(Boolean)[0];
  const navigate = useNavigate();
  return (
    <div
      className={styles.root}
      onClick={() => navigate(`/${parkName}/news/detail/${post?.id}`)}
    >
      <div className={styles.primary}>{post?.attributes.title}</div>

      <div className={styles.image}>
        <img
          src={`${post?.attributes.bg_img.data.attributes.url}`}
          alt="封面图"
        />
      </div>
      <div className={styles.meta}>
        {dayjs
          .utc(
            post.attributes.post_date
              ? post.attributes.post_date
              : post.attributes.publishedAt
          )
          .format("YYYY年MM月DD日")}
      </div>
    </div>
  );
}
