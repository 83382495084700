import { NavBar, Tabs } from "antd-mobile";
// import { Blog1 } from "../../components/Blog";
import Section from "../../components/Section";
import BottomBar from "../../components/BottomBar";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { reqFilterPosts } from "../../service/post";
import { reqStrapiPostCategory } from "../../api/posts";

import PostList from "./postList";
export default function Index() {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const [category, setCategory] = useState([]);

  const onChange = (val) => {
    const result = pathname.slice(0, pathname.lastIndexOf("/")) + "/" + val;
    navigate(result, { replace: true });

    return;
  };
  useEffect(() => {
    reqStrapiPostCategory().then((res) => {
      setCategory(res.data);
    });
  }, [pathname]);
  return (
    <>
      <div className={styles.root}>
        <NavBar backArrow={false}>环球视角</NavBar>
        <Section>
          <Tabs
            className={styles.tabs}
            activeKey={params.id}
            onChange={onChange}
          >
            {category.map((item) => (
              <Tabs.Tab title={item?.attributes.category_name} key={item.id}>
                <PostList
                  postIndex={item.id}
                  categoryName={item?.attributes.category_name}
                />
              </Tabs.Tab>
            ))}
          </Tabs>
        </Section>
      </div>
      <BottomBar />
    </>
  );
}
