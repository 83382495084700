import styles from "./login.module.css";

import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";

import { Form, Input, Button, Footer, NavBar, NoticeBar } from "antd-mobile";

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { STATUS } from "../constants";
// import { reqLogin, reqParkId, LoginOut } from "../service/home/login";
import { reqPark } from "../service/home/login";
import Auth from "../providerRoute";
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState(STATUS.idle);
  const [loading, setLoading] = useState(false);
  // const pathname = location.state?.referral?.pathname;
  const parkName = location.pathname.split("/").filter(Boolean)[0];
  const [park, setPark] = useState();
  const [visible, setVisible] = useState(false);
  const [tip, setTip] = useState("");
  const { login } = Auth();
  const onFinish = (values) => {
    const doPost = async () => {
      setStatus(STATUS.loading);
      setTip("");
      setLoading(true);
      login(values, setStatus, setLoading, setTip);
    };
    doPost();
  };

  const Email = /^\w+([-.]\w+)*@\w+([.-]\w+)*\.\w{2,4}$/;
  const emailValidator = (_, value) => {
    if (!value || Email.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("您输入的邮箱格式不正确！");
  };
  useEffect(() => {
    if (parkName) {
      reqPark(parkName).then((res) => {
        if (res.data.length > 0) {
          setPark(res.data[0]);
        }
      });
    }
  }, [parkName]);
  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <NavBar onBack={() => navigate(`/${parkName}/home`)}>登录</NavBar>
        <div className={styles.header}>
          <img src={park && park.img_url} alt="封面图" />
          {status === STATUS.failure && (
            <NoticeBar content={tip ? tip : "用户名或密码错误"} color="error" />
          )}
        </div>
        <Form
          layout="horizontal"
          onFinish={onFinish}
          footer={
            <>
              <Button loading={loading} type="submit" color="primary" block>
                立即登录
              </Button>
              <div className={styles.link_box}>
                <Link className={styles.link} to={`/${parkName}/recovery`}>
                  忘记了密码？
                </Link>
                <Link className={styles.link} to={`/${parkName}/register`}>
                  立即注册
                </Link>
              </div>
            </>
          }
        >
          <Form.Header>用户登录</Form.Header>
          <Form.Item
            name="username"
            label={
              <span>
                <MailOutlined style={{ marginRight: ".5rem" }} />
                邮箱
              </span>
            }
            rules={[
              { required: true, message: "邮箱地址不能为空" },
              {
                validator: emailValidator,
              },
            ]}
            required={false}
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>
          <Form.Item
            name="password"
            label={
              <span>
                <LockOutlined style={{ marginRight: ".5rem" }} />
                密码
              </span>
            }
            rules={[{ required: true, message: "密码不能为空" }]}
            required={false}
          >
            <div className={styles.password}>
              <Input
                type={visible ? "text" : "password"}
                placeholder="请输入密码"
              />
              <div className={styles.toggle}>
                {!visible ? (
                  <EyeInvisibleOutline onClick={() => setVisible(true)} />
                ) : (
                  <EyeOutline onClick={() => setVisible(false)} />
                )}
              </div>
            </div>
          </Form.Item>
        </Form>
        <Footer
          content={`© 2023 ${park && park.name}，由环球互通提供技术支持`}
        />
      </div>
    </div>
  );
}
