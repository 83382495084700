import styles from "./root.module.css";

import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { STATUS } from "../constants";
import { UserContext } from "../context";

import { reqVerfiyPark, reqAlreadyLogin } from "../service/home/login";
import Auth from "../providerRoute";
export default function Root() {
  const location = useLocation();
  const { pathname } = location;
  const [parkId, setParkId] = useState();
  const [userId, setUserId] = useState();
  const parkName = location?.pathname.split("/").filter(Boolean)[0];
  const { authed } = Auth();
  useEffect(() => {
    if (authed === STATUS.success) {
      reqAlreadyLogin().then((res) => {
        reqVerfiyPark(res.data.user?.email, parkName).then((res) => {
          if (res.data.length === 0) {
            return;
          }
          setParkId(res.data[0].park_id);
          setUserId(res.data[0].id);
        });
      });
    }
  }, [authed, parkName]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.root}>
      <UserContext.Provider
        value={{ userId: userId, parkId: parkId, parkName: parkName }}
      >
        <Outlet />
      </UserContext.Provider>
    </div>
  );
}
