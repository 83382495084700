import supabase from "..";

export const reqRegister = async (userInfo, parkName) => {
  const { data, error } = await supabase.auth.signUp({
    email: userInfo.email,
    password: userInfo.password,
    options: {
      emailRedirectTo: `http://pk.biaoxq.com/${parkName}/me`,
    },
  });
  const result = {
    data: data ?? [],
    error: error,
  };
  return result;
};

// 重置密码
export const reqResetPasswordForEmail = async (email, password) => {
  const { data, error } = await supabase.auth.updateUser({
    email: email,
    password: password,
    data: { hello: "world" },
  });
  const result = {
    data: data ?? [],
    error,
  };
  return result;
};

// 忘记密码
export const reqRecoveryPassword = async (email, parkName, host) => {
  const result = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `http://pk.biaoxq.com/${parkName}/renew`,
  });
  return result;
};

// 更新用户

export const reqUpdateUser = async (email, password) => {
  const result = await supabase.auth.updateUser({
    email: email,
    password: password,
  });
  return result;
};
