import { Button, Skeleton } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Card1.module.css";

export default function Card1({ id, img_url, title, sub_title }) {
  const location = useLocation();
  const { pathname } = location;
  const parkName = pathname.split("/").filter(Boolean)[0];

  const navigate = useNavigate();
  return (
    <div
      className={styles.root}
      onClick={() => navigate(`/${parkName}/empowerment/${id}`)}
    >
      <div className={styles.header}>
        {!id ? (
          <Skeleton animated style={{ width: "100%", height: "6rem" }} />
        ) : (
          <img src={img_url} alt="封面图" />
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.primary}>{title}</div>
        <div className={styles.secondary}>{sub_title}</div>
        {!id && <Skeleton.Paragraph lineCount={2} animated />}
      </div>
      <div className={styles.footer}>
        <Button color="primary" block>
          立即咨询
        </Button>
      </div>
    </div>
  );
}
