import dayjs from "dayjs";
import supabase from "../..";
export const reqActiviyList = async (activityData) => {
  const { current = 1, pageSize = 10, status, member_id } = activityData;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("member_meeting_members")
    .select(
      `apply_status,member_meeting!inner(id,meeting_title,meeting_date)`,
      {
        count: "exact",
      }
    )
    .eq("member_meeting.is_show", 1)
    .eq("member_meeting.status", status)
    .eq("member_id", member_id)
    .order("apply_status", { ascending: false })
    .order("id", { ascending: false });
  const { data, error } = await query.range(start, end);
  const result = {
    data: data ?? [],
    error: error ?? null,
  };
  return result;
};
export const reqActiviyInfo = async (meeting_id) => {
  let query = supabase
    .from("member_meeting")
    .select(
      `meeting_title,meeting_fee,status,meeting_date,content,issue_type,content`,
      {
        count: "exact",
      }
    )
    .eq("is_show", 1)
    .eq("id", meeting_id);
  const { data, error } = await query;
  const result = {
    data: data ?? [],
    error: error ?? null,
  };
  return result;
};

export const reqApplyStatus = async (meeting_id, member_id) => {
  let query = supabase
    .from("member_meeting_members")
    .select(`apply_status`, {
      count: "exact",
    })
    .eq("meeting_id", meeting_id)
    .eq("member_id", member_id);
  const { data } = await query;
  return data;
};
export const reqInsApplyStatus = async (meeting_id, member_id) => {
  let query = supabase
    .from("member_meeting_members")
    .update({
      apply_status: "1",
      join_date: dayjs(),
    })
    .eq("meeting_id", meeting_id)
    .eq("member_id", member_id);
  const { data } = await query;
  return data;
};
export const reqNewMeeting = async (member_id) => {
  let query = supabase
    .from("member_meeting_members")
    .select("id", { count: "exact" })
    .eq("member_id", member_id)
    .eq("apply_status", "0");
  const { count } = await query;
  return count;
};
