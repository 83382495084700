import supabase from "..";

// 获取自己园区关注的公司的id
export const reqCompanyIds = async (userId) => {
  const { data, count, error } = await supabase
    .from("user_company_park")
    .select(`company_id,id`, {
      count: "exact",
    })
    .eq("user_id", userId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }

  const result = {
    data: data ? data.map((item) => item.company_id) : [],
    count: count ?? 0,
  };
  return result;
};

// 获取关注的公司的列表
export const reqCompanyLists = async (ids, parkId, keywords) => {
  const { data, count, error } = await supabase
    .from("company")
    .select(
      `id,format_name,trademarks,copyrights,copyrightSofts,patents,address,contact,risk_trademark_count,risk_patent_count`,
      {
        count: "exact",
      }
    )
    .eq("park_id", parkId)
    .order("id_com", { ascending: false })
    .in("id", ids);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };

  return result;
};

// 获取订单信息
export const reqOrders = async (userId, parkId) => {
  const { data, count, error } = await supabase
    .from("order")
    .select(`*,brand_empowerment(*)`, {
      count: "exact",
    })
    .eq("park_id", parkId)
    .eq("user_id", userId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 获取特定的订单信息
export const reqFilterOrders = async (userId, parkId, status) => {
  const { data, count, error } = await supabase
    .from("order")
    .select(`*,brand_empowerment(*)`, {
      count: "exact",
    })
    .eq("park_id", parkId)
    .eq("user_id", userId)
    .eq("status", status)
    .order("id", { ascending: false });

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 获取订单的详情
export const reqFilterOrderInfo = async (userId, parkId, orderId) => {
  const { data, count, error } = await supabase
    .from("order")
    .select(`*,brand_empowerment(*)`, {
      count: "exact",
    })
    .eq("id", orderId)
    .eq("park_id", parkId)
    .eq("user_id", userId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 获取聊天内容
export const reqComments = async (orderId, parkId) => {
  const { data, count, error } = await supabase
    .from("comment")
    .select(`*,order_file(*)`, {
      count: "exact",
    })
    .eq("order_id", orderId)
    .eq("park_id", parkId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
//  下载文件
export const reqDown = async (fileName) => {
  const { data, error } = await supabase.storage
    .from("comment_files")
    .download(`${fileName}`);
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 获取园区的名字
export const reqParkName = async (parkId) => {
  const { data, count, error } = await supabase
    .from("park")
    .select("name", {
      count: "exact",
    })
    .eq("id", parkId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
// 上传文件
export const reqUpload = async (file) => {
  const format = file.name.split(".").pop();
  const { data, error } = await supabase.storage
    .from("comment_files")
    .upload(`${file.uid + "." + format}`, file, {
      cacheControl: "3600",
      upsert: false,
    });
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

// 记录文件名字
export const reqUploadName = async (file, commentId) => {
  const files = file.map((item) => ({
    file_name: item.uid + "." + item.name.split(".").pop(),
    old_name: item.name,
    comment_id: commentId,
  }));

  const { data, error } = await supabase.from("order_file").insert(files);

  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 删除单个文件
export const reqDelete = async (file) => {
  const filename = file.uid + "." + file.name.split(".").pop();

  const { data, error } = await supabase.storage
    .from("comment_files")
    .remove([`${filename}`]);
  const result = {
    data: data ?? [],
    error,
  };
  return result;
};
// 增加评论
export const reqInsComment = async (content, parkId, orderId, userId = 1) => {
  const { data, error, count } = await supabase
    .from("comment")
    .insert([
      {
        content: content,
        order_id: orderId,
        park_id: parkId,
        role_flag: userId,
      },
    ])
    .select();

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 获取通知信息
export const reqGetNotice = async (companyId, parkId) => {
  try {
    // Step 1: 查询 member 表获取对应的 member_id
    const { data: membersData, error: membersError } = await supabase
      .from('member')
      .select('id')
      .eq('company_id', companyId)
      .eq('park_id', parkId);

    if (membersError) {
      console.error("获取 member 数据失败:", membersError.message);
    }

    if (!membersData.length) {
      console.warn("没有找到对应的 会员ID 数据");
      return { data: [] };
    }

    // 提取所有 member_id
    const memberIds = membersData.map(member => member.id);

    // Step 2: 查询 member_broadcast 表根据 member_id 和 is_read 为 0
    const { data: noticesData, error: noticesError } = await supabase
      .from('member_broadcast')
      .select('broadcast_id')
      .in('member_id', memberIds)
      .eq('is_read', 0);

    if (noticesError) {
      console.error("获取通知数据失败:", noticesError.message);
    }

    if (!noticesData.length) {
      console.warn("没有找到对应的 通知 数据");
      return { data: [] };
    }

    // 提取所有 broadcast_id
    const broadcastIds = noticesData.map(notice => notice.broadcast_id);

    // Step 3: 查询 park_broadcast 表根据 broadcast_id 和 status 为 1
    const { data: parkBroadcastData, error: parkBroadcastError } = await supabase
      .from('park_broadcast')
      .select('*')
      .in('id', broadcastIds)
      .eq("status", "1") // 只查询状态为 1(发布) 的通知

    if (parkBroadcastError) {
      console.error("获取 通知 表失败:", parkBroadcastError.message);
    }

    // Step 4: 返回数据
    return {
      data: parkBroadcastData ?? [],
    };
  } catch (error) {
    console.error("获取通知列表数据失败:", error.message);
    return { data: [] };
  }
}
