import { Button, List, Mask, NavBar, SpinLoading } from "antd-mobile";
import styles from "./index.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { reqActiviyInfo } from "../../../../service/member/activity";
import { useEffect, useState } from "react";
import { STATUS } from "../../../../constants";
import { activity_status } from "../../../../intlClass";
import qs from "query-string";

import {
  reqApplyStatus,
  reqInsApplyStatus,
} from "../../../../service/member/activity";
import dayjs from "dayjs";
export default function ActivityDetail() {
  const navigate = useNavigate();
  const { pid: meeting_id } = useParams();
  const { search } = useLocation();
  const [status, setStatus] = useState(STATUS.idle);
  const [meetingInfo, setMeetingInfo] = useState();
  const [applyStatus, setApplyStatus] = useState(0);
  const { member_id } = qs.parse(search);

  const ActivityTableConfig = [
    {
      title: "活动主题",
      dataIndex: "meeting_title",
    },
    {
      title: "主要内容",
      dataIndex: "content",
    },
    {
      title: "时间",
      dataIndex: "meeting_date",
    },
    {
      title: "类型",
      dataIndex: "issue_type",
    },

    {
      title: "费用（元）",
      dataIndex: "meeting_fee",
    },
    {
      title: "状态",
      dataIndex: "status",
    },
  ];
  const handleApply = async () => {
    await reqInsApplyStatus(meeting_id, member_id).then(() => {
      setStatus(STATUS.idle);
    });
  };
  useEffect(() => {
    const doPost = async () => {
      await reqApplyStatus(meeting_id, member_id).then((res) => {
        setApplyStatus(res[0].apply_status);
      });
      await reqActiviyInfo(meeting_id).then((res) => {
        setMeetingInfo(res.data[0]);
        setStatus(STATUS.success);
      });
    };
    if (meeting_id && status === STATUS.idle) {
      doPost();
    }
  }, [meeting_id, member_id, status]);
  useEffect(() => {
    if (status === STATUS.success) {
      setStatus(STATUS.idle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <>
      <div className={styles.root}>
        <Mask color="white" visible={status !== STATUS.success}>
          <SpinLoading
            style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
          ></SpinLoading>
        </Mask>
        <NavBar onBack={() => navigate(-1)}>活动详情</NavBar>
        <List header={"基本信息"}>
          {meetingInfo && (
            <>
              {ActivityTableConfig.map((item, index) => (
                <List.Item key={index} title={item.title}>
                  {item.dataIndex === "meeting_date"
                    ? dayjs(meetingInfo[item.dataIndex]).format("YYYY-MM-DD")
                    : item.dataIndex === "status"
                    ? activity_status[meetingInfo[item.dataIndex]].title
                    : meetingInfo[item.dataIndex]}
                </List.Item>
              ))}
            </>
          )}
        </List>
        {meetingInfo && (
          <div className={styles.btn}>
            <Button
              color="primary"
              disabled={applyStatus}
              size="large"
              onClick={() => handleApply()}
              style={{ width: "10rem" }}
            >
              {applyStatus ? "已报名" : "我要报名"}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
