import styles from "./MyMask.module.css";
import { Button, Space, Mask, Result } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";

export default function MyMask() {
  const navigate = useNavigate();
  const location = useLocation();
  const parkName = location?.pathname.split("/").filter(Boolean)[0];

  return (
    <Mask color="#FFFFFF" style={{ position: "relative", height: "100vh" }}>
      <div className={styles.mask}>
        <Result
          status="warning"
          title="暂未登陆"
          description="您还没有登陆账号，请登录后再试。"
        />
        <Space
          direction="vertical"
          block
          style={{ marginTop: "3rem", "--gap": "1rem" }}
        >
          <Button
            color="primary"
            block
            size="large"
            onClick={() => navigate(`/${parkName}/login`)}
          >
            立即登陆
          </Button>

          <Button
            size="large"
            block
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        </Space>
      </div>
    </Mask>
  );
}
