import { Link } from 'react-router-dom';
import { NumberOutlined, RightOutlined } from '@ant-design/icons';
import styles from './Section.module.css';

export default function Section({ title, to, children, ...props }) {
  return (
    <section className={styles.root} { ...props }>
      {
        title && (
          <div className={styles.header}>
            <div className={styles.title}><NumberOutlined />{ title }</div>
            { to && <div className={styles.link}><Link to={to}>查看更多<RightOutlined /></Link></div> }
          </div>
        )
      }
      <div className={styles.body}>
        { children }
      </div>
    </section>
  )
}