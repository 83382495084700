import axios from "axios";
class SHRequest {
  instance;
  constructor(config) {
    this.instance = axios.create(config);
    this.instance.interceptors.request.use(
      (config) => {
        if (config.headers) {
          config.headers.Authorization =
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inpldmd3cW9lbnhraGt2b3JubWR3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODY1MzU3NTIsImV4cCI6MjAwMjExMTc1Mn0.UdjdfE_8Q4JiUC8KdFYC-zAwJJDrfiO6mlUkEdQKA_U";
        }
        return config;
      },
      (error) => {
        return error;
      }
    );
    this.instance.interceptors.response.use(
      (res) => {
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  }
  request(config) {
    return new Promise((resolve, reject) => {
      this.instance
        .request(config)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  get(config) {
    return this.instance.request({ ...config, method: "GET" });
  }
  post(config) {
    return this.instance.request({ ...config, method: "POST" });
  }
  put(config) {
    return this.instance.request({ ...config, method: "PUT" });
  }
  delete(config) {
    return this.instance.request({ ...config, method: "DELETE" });
  }
}
export default SHRequest;
