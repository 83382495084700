import dayjs from "dayjs";
import styles from "./index.module.css";
import { Button } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";

export default function ActivityCard({ data }) {
  const { member_meeting, apply_status } = data;
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const goDetail = (id) => {
    const result = pathname + "/" + id + search;
    navigate(result);
  };
  return (
    <>
      <div className={styles.root}>
        {apply_status === 1 && <div className={styles.icon}>已报名</div>}
        <div className={styles.title}>{member_meeting.meeting_title}</div>

        <div className={styles.content}>
          时间：{dayjs(member_meeting.meeting_date).format("YYYY-MM-DD")}
        </div>

        <div className={styles.btn}>
          <Button
            onClick={() => goDetail(member_meeting.id)}
            color="primary"
            size="small"
          >
            查看详情
          </Button>
        </div>
      </div>
    </>
  );
}
