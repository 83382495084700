import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import qs from "query-string";
import { Button, NavBar, Form, Input, SpinLoading, Mask } from "antd-mobile";
import { UserContext } from "../../../../context";
import { reqCompany } from "../../../../service/company";

import {
  reqInsertMember,
  reqMemberWithComId,
} from "../../../../service/member/vip/index.";
import { STATUS } from "../../../../constants";
export default function Vip() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [comId, setComId] = useState();
  const { userId, parkId } = useContext(UserContext);
  const [form] = Form.useForm();
  const [applyData, setApplyData] = useState();
  const [status, setStatus] = useState(STATUS.idle);
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);
      const value = await form.validateFields();
      const memberData = {
        ...value,
        park_id: parkId,
        company_id: comId,
        status: 2,
      };
      await reqInsertMember(memberData).then(() => {
        setLoading(false);
        setStatus(STATUS.idle);
      });
    } catch (error) {}
  };
  useEffect(() => {
    const { com_id } = qs.parse(search);
    setComId(com_id);
    if (userId && comId && status === STATUS.idle) {
      reqCompany(userId, comId).then((res) => {
        form.setFieldValue("member_name", res.data[0].com_name);
      });
      reqMemberWithComId({
        company_id: comId,
        park_id: parkId,
      }).then((res) => {
        if (res.count > 0) {
          setApplyData(res.data[0]);
          form.setFieldsValue(res.data[0]);
        }
        setStatus(STATUS.success);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, userId, comId, parkId, status]);
  return (
    <>
      <div className={styles.root}>
        <Mask visible={status !== STATUS.success} color="white">
          <SpinLoading
            style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
          ></SpinLoading>
        </Mask>
        <NavBar backArrow={true} onBack={() => navigate(-1)}>
          会员申请表
        </NavBar>
        <Form
          form={form}
          footer={
            applyData && applyData.status === 2 ? (
              <Button block disabled color="primary" size="large">
                申请已提交，请耐心等待。
              </Button>
            ) : applyData && applyData.status === 3 ? (
              <Button
                block
                loading={loading}
                type="submit"
                color="primary"
                size="large"
              >
                申请失败，点击重新提交
              </Button>
            ) : (
              <Button
                block
                loading={loading}
                type="submit"
                color="primary"
                size="large"
              >
                提交
              </Button>
            )
          }
          onFinish={onFinish}
        >
          <Form.Header>基本信息</Form.Header>
          <Form.Item
            name="member_name"
            label="公司"
            rules={[{ required: true, message: "姓名不能为空" }]}
          >
            <Input disabled placeholder="请输入姓名" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="手机"
            rules={[
              { required: true, message: "手机号码不能为空" },
              {
                pattern: /^1[3|415178][0-9]{9}$/, // 使用正则表达式验证手机号码格式
                message: "手机号码格式不正确",
              },
            ]}
          >
            <Input placeholder="请输入手机号码" />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
