import { useLocation, useNavigate } from "react-router-dom";
import { NavBar, List } from "antd-mobile";
import { Blog1 } from "../../components/Blog";
import styles from "./details.module.css";
import { useEffect, useState } from "react";
import { reqStrapiPostInfo, reqStrapiRecommendPosts } from "../../api/posts";
import ReactMarkdown from "react-markdown";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
export default function Details() {
  dayjs.extend(utc);
  const { pathname } = useLocation();
  const parkName = pathname.split("/").filter(Boolean)[0];
  const postId = pathname.split("/").filter(Boolean).pop();
  const [isRecommendPosts, setIsRecommendPosts] = useState([]);
  const navigate = useNavigate();
  const [postInfo, setPostInfo] = useState();
  useEffect(() => {
    reqStrapiRecommendPosts(parkName).then((res) => {
      setIsRecommendPosts(res.data);
    });
    reqStrapiPostInfo(parkName, postId).then((res) => {
      // 获取文章详情
      setPostInfo(res.data);
    });
  }, [parkName, postId]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>环球视角</NavBar>
      {postInfo && (
        <List>
          <List.Item>
            <img
              className={styles.feature}
              src={`${postInfo.attributes.bg_img.data.attributes.url}`}
              alt="封面图"
            />
            <div className={styles.title}>{postInfo.attributes.title}</div>
          </List.Item>
          <List.Item>
            <div className={styles.meta}>
              发布于：
              {dayjs
                .utc(
                  postInfo.attributes.post_date
                    ? postInfo.attributes.post_date
                    : postInfo.attributes.publishedAt
                )
                .format("YYYY年MM月DD日")}
            </div>
          </List.Item>
          <List.Item>
            <ReactMarkdown className={styles.content}>
              {postInfo.attributes.content}
            </ReactMarkdown>
          </List.Item>
        </List>
      )}

      <List header="推荐阅读" style={{ marginTop: "1rem" }}>
        {isRecommendPosts.map((item) => (
          <List.Item key={item.id} arrow={false} clickable>
            <Blog1 post={item} />
          </List.Item>
        ))}
      </List>
      <div className={styles.footer}>~ 到底了 ~</div>
    </>
  );
}
