import { Button, Input, SafeArea, ProgressBar } from "antd-mobile";
import { useDropzone } from "react-dropzone";
import {
  PaperClipOutlined,
  SendOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styles from "./ChatInput.module.css";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  reqDelete,
  reqInsComment,
  reqUpload,
  reqUploadName,
} from "../service/me";
import { UserContext } from "../context";
// import { reqUpload } from "../service/demo";
export default function ChatInput({ orderId, setVisible }) {
  const [files, setFiles] = useState([]);
  const { parkId } = useContext(UserContext);
  const [InputValue, setInputValue] = useState("");
  const [isShowProg, setIsShowProg] = useState(false);
  const [upLen, setUpLen] = useState(0);
  const [upFieLen, setUpFieLen] = useState(0);

  const generateUID = () => {
    const timestamp = Date.now();
    const randomChars = Math.random().toString(36).substring(2, 10);
    const uid = `${timestamp}-${randomChars}`;

    return uid;
  };

  const onDrop = useCallback((acceptedFiles) => {
    setIsShowProg(true);
    setUpLen(0);
    setUpFieLen(0);
    setUpFieLen(acceptedFiles.length);
    acceptedFiles.forEach((file, index) => {
      file.uid = generateUID();
    });
    acceptedFiles.map((item) =>
      reqUpload(item).then(() => {
        setUpLen((preLen) => preLen + 1);
        setFiles((pre) => pre.concat(item));
      })
    );
  }, []);

  const handleDel = (file) => {
    reqDelete(file);
    setFiles((preFileLists) =>
      preFileLists.filter((item) => item.uid !== file.uid)
    );
  };
  useEffect(() => {
    if (files.length === 0) {
      setIsShowProg(false);
    }
  }, [files]);
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  const fileLists = files.map((item, index) => (
    <div className={styles.item} key={item.uid}>
      <a href="/#">
        <PaperClipOutlined />
        {item.name}
      </a>
      <Button color="danger" fill="none" onClick={() => handleDel(item)}>
        <DeleteOutlined />
      </Button>
    </div>
  ));
  const onChange = (value) => {
    setInputValue(value);
  };
  const handleSubmit = () => {
    if (orderId) {
      reqInsComment(InputValue, parkId, orderId).then((res) => {
        reqUploadName(files, res.data[0].id);
        setInputValue("");
        setFiles([]);
        setVisible(true);
      });
    }
  };
  useEffect(() => {}, [files]);
  return (
    <div className={styles.root}>
      <div className={styles.body} {...getRootProps()}>
        <input {...getInputProps()}></input>
        <Input onChange={onChange} value={InputValue} />
        <Button color="primary" fill="none" onClick={open}>
          <PaperClipOutlined />
        </Button>
        <Button
          disabled={files.length === 0 && InputValue === ""}
          color="primary"
          onClick={handleSubmit}
        >
          <SendOutlined />
        </Button>
      </div>

      {isShowProg ? (
        <ProgressBar percent={Math.floor((100 * upLen) / upFieLen)} text />
      ) : (
        ""
      )}
      <div className={styles.attachments}>{fileLists && fileLists}</div>
      <SafeArea position="bottom" />
    </div>
  );
}
