import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  NavBar,
  List,
  Footer,
  Tag,
  NoticeBar,
  Mask,
  SpinLoading,
} from "antd-mobile";
import { reqRiskLists, reqPatentInfo } from "../../../service/me/monitoring";
import styles from "./index.module.css";

export default function Patent() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const [visible, setVisible] = useState(true);
  const [riskPt, setRiskPt] = useState([]);
  const [ptInfo, setPtInfo] = useState({});

  useEffect(() => {
    if (params.id) {
      const ptIndex = Number.parseInt(search.split("?").pop() - 1);
      reqRiskLists(params.id, "risk_patent").then((res) => {
        if (res.data.length > 0 || isNaN(ptIndex)) {
          const arr = JSON.parse(res.data[0].risk_patent);
          if (ptIndex > arr.length) {
            return;
          }
          const result = arr[ptIndex];
          setRiskPt(result);

          result.request_num &&
            reqPatentInfo(result.request_num).then((res) => {
              setPtInfo(res.data.length > 0 ? res.data[0] : {});
              setVisible(false);
            });
        }
      });
    }
  }, [params.id, search]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>专利监测</NavBar>
      <Mask visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <NoticeBar color="alert" content="该专利状态存在风险，请及时处理" />
      <List header="基本信息">
        <List.Item title="名称">{riskPt.patent_name}</List.Item>
        <List.Item title="类型">{riskPt.type_name}</List.Item>
        <List.Item title="风险">
          <Tag color="warning">待缴年费</Tag>
        </List.Item>
        <List.Item title="列入日期">{riskPt.outhor_date}</List.Item>
        <List.Item title="法律状态">{ptInfo.last_status}</List.Item>
        <List.Item title="申请日期">{ptInfo.request_date ?? "-"}</List.Item>
        <List.Item title="申请号">{ptInfo.request_num ?? "-"}</List.Item>
        <List.Item title="授权公告号">{ptInfo.outhor_num ?? "-"}</List.Item>
        <List.Item title="授权公告时间">{ptInfo.outhor_date ?? "-"}</List.Item>
        <List.Item title="申请（专利权）人">
          {ptInfo.patent_person ?? "-"}
        </List.Item>
        <List.Item title="发明人 / 设计人">{ptInfo.designer ?? "-"}</List.Item>
        <List.Item title="IPC/LOC分类">
          <ul className={styles.list}>
            {ptInfo.category_num &&
              ptInfo.category_num
                .split(";")
                .map((item) => <li key={item}>{item}</li>)}
          </ul>
        </List.Item>
      </List>
      <List header="摘要">
        <List.Item>{ptInfo.brief ?? "-"}</List.Item>
      </List>
      <List header="附图">
        <List.Item>
          <Link to={ptInfo.annex}>专利证明.pdf</Link>
        </List.Item>
      </List>
      <Footer style={{ padding: "1rem 0 3rem 0" }} content="~ 到底了 ~" />
    </>
  );
}
