import styles from "./details.module.css";
import {
  NavBar,
  List,
  Button,
  Space,
  SafeArea,
  SpinLoading,
  Mask,
  Skeleton,
} from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { reqEmpowermentInfo } from "../../service/empowerment";
import { useEffect, useState } from "react";
export default function Details() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const [empowermentInfo, setEmpowermentInfo] = useState();
  const parkName = location.pathname.split("/").filter(Boolean)[0];
  const empowermentId = location.pathname.split("/").filter(Boolean)[2];
  useEffect(() => {
    if (!isNaN(empowermentId)) {
      reqEmpowermentInfo(empowermentId).then((res) => {
        if (res.data.length > 0) setEmpowermentInfo(res.data[0]);
        setVisible(false);
      });
    } else {
      setVisible(false);
    }
  }, [empowermentId, parkName]);
  return (
    <div className={styles.root}>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>

      <NavBar onBack={() => navigate(-1)}>
        {empowermentInfo && empowermentInfo.title}
      </NavBar>
      <List>
        <List.Item>
          <img
            className={styles.feature}
            src={empowermentInfo && empowermentInfo.img_url}
            alt="封面图"
          />
        </List.Item>
        <List.Item>
          <div className={styles.meta}>
            服务代码：{empowermentInfo && empowermentInfo.id}
            <br />
            温馨提示：本服务由环球互通提供支持
          </div>
        </List.Item>
        <List.Item>
          <div className={styles.title}>
            {empowermentInfo && empowermentInfo.title}
          </div>
          <div className={styles.subtitle}>
            {empowermentInfo &&
              empowermentInfo.content
                .split(";")
                .filter(Boolean)
                .map((item, index) => (
                  <div key={index}>
                    <span>{item}</span>
                    <br />
                  </div>
                ))}
          </div>
          <div className={styles.highlights}>
            <img
              className={styles.stamp}
              src="/assets/bg-certificated.png"
              alt="我们的承诺"
            />
            {empowermentInfo &&
              empowermentInfo.introduction.split(";").map((item, index) => (
                <div className={styles.item} key={index}>
                  <CheckCircleOutlined />
                  {item}
                  <br />
                </div>
              ))}
          </div>
        </List.Item>
      </List>
      <List header="服务详情" style={{ marginTop: ".5rem" }}>
        <div
          style={{
            width: "100%",
            backgroundColor: "#CCCCCC",
          }}
        >
          {empowermentInfo ? (
            <img
              style={{ width: "100%" }}
              src={empowermentInfo.img_info_url}
              alt="封面"
            />
          ) : (
            <Skeleton active style={{ width: "100%", height: 250 }} />
          )}
        </div>
      </List>
      <div className={styles.footer}>~ 到底了 ~</div>
      <div className={styles.actions}>
        <Space direction="vertical" block>
          <Button
            color="primary"
            onClick={() =>
              navigate(`/${parkName}/empowerment/create/${empowermentId}`)
            }
            block
          >
            立即咨询
          </Button>
          <Button color="primary" fill="outline" block>
            咨询电话：400-006-2018
          </Button>
        </Space>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}
