import supabase from "..";

export const reqAlreadyLogin = async () => {
  // 获取当前用户的身份验证状态
  try {
    const user = await supabase.auth.getUser();
    return user;
  } catch (error) {
    console.log("error :>> ", error);
  }
};

// 用户审计
export const InsAudit = async (userId, operation, park_id) => {
  let query = supabase.from("user_logs").insert([
    {
      user_id: userId,
      operation: operation,
      park_id: park_id,
    },
  ]);
  const { data, error } = await query;
  const result = {
    data: data ?? [],
    error: error,
  };
  return result;
};

export const reqParkRouter = async (parkName) => {
  const { data, error } = await supabase
    .from("park")
    .select("*")
    .eq("short_name", parkName);
  const result = {
    data: data ?? [],
    error,
  };
  return result;
};

export const reqLogin = async (values) => {
  let data = await supabase.auth.signInWithPassword({
    email: values.username,
    password: values.password,
  });
  if (data.error !== null) {
    throw new Error("账户密码错误");
  }
  return data;
};

export const reqParkId = async (email, parkName) => {
  const result = await supabase.from("user").select("*").eq("mail", email);
  return result;
};

export const reqVerfiyPark = async (email, parkName) => {
  const { data, error } = await supabase
    .from("user")
    .select("*")
    .eq("mail", email)
    .eq("short_name", parkName);
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

// 获取园区信息

export const reqPark = async (parkName) => {
  const { data, error } = await supabase
    .from("park")
    .select("*")
    .eq("short_name,id", parkName);
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 获取园区列表
export const reqParks = async () => {
  const result = await supabase
    .from("park")
    .select(
      "name,short_name,address,img_url,bg_img_url,logo_img_url,park_role,org_url_pk"
    )
    .eq("is_disable", 0);
  return result;
};
// 退出
export const LoginOut = async () => {
  let { error } = await supabase.auth.signOut();
  return error;
};
