import { useLocation, useNavigate, useParams } from "react-router-dom";
import { List, NavBar, Tag, Mask, SpinLoading } from "antd-mobile";
import { useEffect, useState } from "react";
import { reqRiskLists } from "../../../service/me/monitoring";

export default function Risk() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const [visible, setVisible] = useState(true);
  const [abnormal, setAbnormal] = useState({});
  useEffect(() => {
    if (params.id) {
      const abnormalIndex = Number.parseInt(search.split("?").pop() - 1);
      reqRiskLists(params.id, "abnormal_items").then((res) => {
        if (res.data.length > 0) {
          const arr = JSON.parse(res.data[0].abnormal_items);
          if (abnormalIndex > arr.length) {
            return;
          }
          const result = arr[abnormalIndex];
          setAbnormal(result);
        }
        setVisible(false);
      });
    }
  }, [params.id, search]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>经营风险</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <List header="基本信息">
        <List.Item title="触发时间">{abnormal.in_date}</List.Item>
        <List.Item title="触发部门">{abnormal.department}</List.Item>
        <List.Item title="类型">{abnormal.in_reason}</List.Item>
        <List.Item title="风险等级">
          {abnormal.is_history === 0 ? (
            <Tag color="red">高风险</Tag>
          ) : (
            <Tag color="green">低风险</Tag>
          )}
        </List.Item>
        <List.Item title="移除时间">{abnormal.out_date}</List.Item>
        <List.Item title="风险描述">{abnormal.out_reason}</List.Item>
      </List>
    </>
  );
}
