import dayjs from "dayjs";
import supabase from "../..";
// 判断是否是会员
export const reqIsMember = async (parkId, com_id) => {
  let query = supabase
    .from("member")
    .select("id,company_id")
    .eq("park_id", parkId)
    .eq("company_id", com_id)
    .eq("status", 1);
  const { data, error } = await query;
  if (error) {
    console.log(error);
    return { error };
  }

  return data.length > 0 ? true : false;
};

export const reqMemberList = async (parkId, com_id) => {
  let query = supabase
    .from("member")
    .select("id,company_id")
    .eq("park_id", parkId)
    .eq("status", 1)
    .eq("is_delete", 1); // 未删除的会员
  const { data, error } = await query;
  if (error) {
    console.log(error);
    return { error };
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

// 向member数据库插入用户
export const reqInsertMember = async (memberData) => {
  const { data, count: existingMembersCount } = await reqMemberWithComId(
    memberData
  );
  if (existingMembersCount === 0) {
    let query = supabase
      .from("member")
      .insert([{ ...memberData, status: 2, fee_status: 2 }])
      .select();
    const { data, error } = await query;

    if (error) {
      throw new Error(error.message);
    }
    return data;
  } else if (data[0].status === 2) {
    throw new Error("申请已提交，请耐心等待");
  } else if (data[0].status === 3) {
    await reqUpdateMemberStatus({ ...memberData, created_at: dayjs() });
  }
};

// member中是否存在该用户
export const reqMemberWithComId = async (memberData) => {
  const { data, count } = await supabase
    .from("member")
    .select("status,company_id,park_id,phone", { count: "exact" })
    .eq("park_id", memberData.park_id)
    .eq("company_id", memberData.company_id);
  const result = {
    data,
    count,
  };
  return result;
};

// 向member数据库修改用户
export const reqUpdateMemberStatus = async (memberData) => {
  const { data, error } = await supabase
    .from("member")
    .update(memberData)
    .eq("company_id", memberData.company_id)
    .eq("park_id", memberData.park_id);
  if (error) {
    throw new Error(error.message);
  }
  return data;
};
