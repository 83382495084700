import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Root from "./pages/root";
import Login from "./pages/login";
import Index from "./pages";
import News from "./pages/news";
import NewsDetails from "./pages/news/details";
import Empowerment from "./pages/empowerment";
import EmpowermentDetails from "./pages/empowerment/details";
import EmpowermentCreate from "./pages/empowerment/create";
import Me from "./pages/me";
import MyAccount from "./pages/me/account";
import MyCompany from "./pages/me/company";
import MyProperty from "./pages/me/property";
import MyPropertyTrademark from "./pages/me/property/trademark";
import MyPropertyPatent from "./pages/me/property/patent";
import MyPropertyCopyright from "./pages/me/property/copyright";
import MyPropertySoftwareCopyright from "./pages/me/property/software-copyright";
import MyMonitoring from "./pages/me/monitoring";
import MyMonitoringRisk from "./pages/me/monitoring/risk";
import MyMonitoringTrademark from "./pages/me/monitoring/trademark";
import MyMonitoringPatent from "./pages/me/monitoring/patent";
import MyConsulting from "./pages/me/consulting";
import MyConsultingDetails from "./pages/me/consulting/details";
import MyRegister from "./pages/register";
import Forbidden from "./pages/forbidden";
import AboutUs from "./pages/about-us";
import MailBox from "./pages/mailbox";
import Recovery from "./pages/recovery";
import Renew from "./pages/recovery/renew";
import { AuthProvider, RequireAuth, RequireRole } from "./providerRoute";
import Forehead from "./components/forehead";
import Transaction from "./pages/transaction";
import TransactionDetails from "./pages/transaction/details";
import Vip from "./pages/me/member/vip";
import Activity from "./pages/me/member/activity";
import ActivityDetail from "./pages/me/member/activity/detail";
import Notice from "./pages/me/notice/index";
import NoticeDetail from "./pages/me/notice/detail";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Forehead />} />
          <Route path="/forbidden" element={<Forbidden />} />
          <Route path="/:pid/login" element={<Login />} />
          <Route path="/:pid/register" element={<MyRegister />} />
          <Route path="/:pid/register/mailbox" element={<MailBox />} />
          <Route path="/:pid/recovery" element={<Recovery />} />
          <Route path="/:pid/renew" element={<Renew />} />
          <Route path="/:pid" element={<Root />}>
            <Route path="home" element={<Index />} />
            <Route path="news/:id" element={<News />} />
            <Route path="news/detail/:id" element={<NewsDetails />} />
            <Route path="empowerment" element={<Empowerment />} />
            <Route
              path="empowerment/create/:id"
              element={
                <RequireAuth>
                  <EmpowermentCreate />
                </RequireAuth>
              }
            />
            <Route path="empowerment/:id" element={<EmpowermentDetails />} />
            <Route
              path="transaction/:id"
              element={
                <RequireAuth>
                  <Transaction />
                </RequireAuth>
              }
            />
            <Route
              path="transaction/details/:id"
              element={
                <RequireAuth>
                  <TransactionDetails />
                </RequireAuth>
              }
            />
            <Route
              path="me"
              element={
                <RequireAuth>
                  <Me />
                </RequireAuth>
              }
            />
            <Route
              path="me/account"
              element={
                <RequireAuth>
                  <MyAccount />
                </RequireAuth>
              }
            />
            <Route
              path="me/member/vip"
              element={
                <RequireRole>
                  <Vip />
                </RequireRole>
              }
            />
            <Route
              path="me/member/activity"
              element={
                <RequireAuth>
                  <Navigate to="1" />
                </RequireAuth>
              }
            />
            <Route
              path="me/member/activity/:id"
              element={
                <RequireRole>
                  <Activity />
                </RequireRole>
              }
            />
            <Route
              path="me/member/activity/:id/:pid"
              element={
                <RequireRole>
                  <ActivityDetail />
                </RequireRole>
              }
            />
            <Route
              path="me/company"
              element={
                <RequireAuth>
                  <MyCompany />
                </RequireAuth>
              }
            />
            <Route
              path="me/property/:id"
              element={
                <RequireAuth>
                  <MyProperty />
                </RequireAuth>
              }
            />
            <Route
              path="me/property/trademark/:id"
              element={
                <RequireAuth>
                  <MyPropertyTrademark />
                </RequireAuth>
              }
            />
            <Route
              path="me/property/patent/:id"
              element={
                <RequireAuth>
                  <MyPropertyPatent />
                </RequireAuth>
              }
            />
            <Route
              path="me/property/copyright/:id"
              element={
                <RequireAuth>
                  <MyPropertyCopyright />
                </RequireAuth>
              }
            />
            <Route
              path="me/property/software-copyright/:id"
              element={
                <RequireAuth>
                  <MyPropertySoftwareCopyright />
                </RequireAuth>
              }
            />
            <Route
              path="me/monitoring/:id"
              element={
                <RequireAuth>
                  <MyMonitoring />
                </RequireAuth>
              }
            />
            <Route
              path="me/monitoring/risk/:id"
              element={
                <RequireAuth>
                  <MyMonitoringRisk />
                </RequireAuth>
              }
            />
            <Route
              path="me/monitoring/trademark/:id"
              element={
                <RequireAuth>
                  <MyMonitoringTrademark />
                </RequireAuth>
              }
            />
            <Route
              path="me/monitoring/patent/:id"
              element={
                <RequireAuth>
                  <MyMonitoringPatent />
                </RequireAuth>
              }
            />
            <Route
              path="me/notice"
              element={
                <RequireAuth>
                  <Notice />
                </RequireAuth>
              }
            />
            <Route
              path="me/notice/detail/:id"
              element={
                <RequireAuth>
                  <NoticeDetail />
                </RequireAuth>
              }
            />
            <Route
              path="me/consulting/:id"
              element={
                <RequireAuth>
                  <MyConsulting />
                </RequireAuth>
              }
            />
            <Route
              path="me/consulting/detail/:id"
              element={
                <RequireAuth>
                  <MyConsultingDetails />
                </RequireAuth>
              }
            />

            <Route path="about-us" element={<AboutUs />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
