import { useContext, useState } from "react";
// import { reqFilterPosts } from "../../service/post";
import { InfiniteScroll, List } from "antd-mobile";
import { Blog1 } from "../../components/Blog";
import { UserContext } from "../../context";
import { reqStrapiPosts } from "../../api/posts";
export default function PostList({ postIndex, categoryName }) {
  const { parkName } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [postLists, setPostLists] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async (page) => {
    // const append = await reqFilterPosts(postIndex, parkName, page);
    const append = await reqStrapiPosts(page, parkName, categoryName);
    setPostLists((val) => [...val, ...append.data]);
    setHasMore(append.data.length === 10);
    setPage(page + 1);
  };
  return (
    <List>
      {postLists.map((item) => (
        <List.Item key={item.id} arrow={false} clickable>
          <Blog1 post={item} />
        </List.Item>
      ))}
      <InfiniteScroll loadMore={() => loadMore(page)} hasMore={hasMore} />
    </List>
  );
}
