import { NavBar, List, Footer, Mask, SpinLoading } from "antd-mobile";
import styles from "./index.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../context";
import { reqInfo } from "../../../service/me/property";

export default function Copyright() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { parkId } = useContext(UserContext);
  const [cpsInfo, setCpsInfo] = useState({});
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    if (parkId) {
      const id = pathname.split("/").pop();
      reqInfo(
        parkId,
        id,
        "park_copyright_soft",
        "copyright_soft_id",
        "copyright_soft"
      ).then((res) => {
        if (res.data.length > 0) {
          setCpsInfo(res.data[0].copyright_soft);
        }
        setVisible(false);
      });
    }
  }, [pathname, parkId]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>软件著作权</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <List header="基本信息">
        <List.Item title="名称">{cpsInfo.name}</List.Item>
        <List.Item title="登记号">{cpsInfo.number}</List.Item>
        <List.Item
          title="作品分类"
          prefix={
            <img
              className={styles.icon}
              src="/assets/icon/ico-copyright-2.png"
              alt="专利类型"
            />
          }
        >
          {cpsInfo.type_name}
        </List.Item>
        <List.Item title="简称">{cpsInfo.short_name}</List.Item>
        <List.Item title="版本">{cpsInfo.version}</List.Item>
        <List.Item title="创作时间">{cpsInfo.success_date}</List.Item>
        <List.Item title="发布时间">{cpsInfo.first_date}</List.Item>
        <List.Item title="登记人">{cpsInfo.company}</List.Item>
        <List.Item title="登记时间">{cpsInfo.approval_date}</List.Item>
      </List>
      <Footer style={{ padding: "1rem 0 3rem 0" }} content="~ 到底了 ~" />
    </>
  );
}
