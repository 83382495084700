import { SpinLoading } from "antd-mobile";
export default function MySpin() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "#ffffff",
      }}
    >
      <SpinLoading style={{ "--size": "48px", margin: "auto" }} />
    </div>
  );
}
