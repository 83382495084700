import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "../vip/index.module.css";
import { NavBar, Tabs } from "antd-mobile";
import { activity_status } from "../../../../intlClass";
import Section from "../../../../components/Section";
import ActivityList from "./cpn/ActivityList";
export default function Activity() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { id } = useParams();
  const onChange = (val) => {
    const result =
      pathname.slice(0, pathname.lastIndexOf("/")) + "/" + val + search;
    navigate(result, { replace: true });
    return;
  };
  return (
    <>
      <div className={styles.root}>
        <NavBar backArrow={true} onBack={() => navigate(-1)}>
          园区活动
        </NavBar>
        <Section>
          <Tabs className={styles.tabs} activeKey={id} onChange={onChange}>
            {activity_status.map((item) => (
              <Tabs.Tab title={item.title} key={item.id}>
                <ActivityList postIndex={item.id} status={id} />
              </Tabs.Tab>
            ))}
          </Tabs>
        </Section>
      </div>
    </>
  );
}
