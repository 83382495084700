import { Swiper, Tabs, Grid, List, SpinLoading, Mask } from "antd-mobile";
import { range } from "lodash";
import Section from "../components/Section";
import Jumbo from "../components/Jumbo";
import { Card1, Card2 } from "../components/Card";
import { Blog1 } from "../components/Blog";
import BottomBar from "../components/BottomBar";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { reqEmpowerment } from "../service/empowerment";
import { reqPark } from "../service/home/login";
import { reqStrapiRecommendPosts } from "../api/posts";
import { clazzes } from "../intlClass";
import { reqTmGoods } from "../service/transaction";
export default function Index() {
  const { pathname } = useLocation();
  const [empowerments, setEmpowerments] = useState([]);
  const [isRecommendPosts, setIsRecommendPosts] = useState([]);
  const [tmgoods, setTmgoods] = useState([]);
  const [visible, setVisible] = useState(true);
  const [park, setPark] = useState();

  const parkName = pathname.split("/").filter(Boolean)[0];
  const handleTabChange = (key) => {
    reqTmGoods(6, 1, key).then((res) => {
      setTmgoods(res.data);
    });
  };
  useEffect(() => {
    if (parkName) {
      reqPark(parkName).then((res) => {
        setPark(res.data[0]);
      });
      reqEmpowerment().then((res) => {
        setEmpowerments(res.data);
      });
      reqStrapiRecommendPosts(parkName).then((res) => {
        setIsRecommendPosts(res.data);
        setVisible(false);
      });
    }
    handleTabChange(1);
  }, [pathname, parkName]);
  return (
    <>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <Section>
        <Swiper indicator={() => {}}>
          {park ? (
            <Swiper.Item>
              <Jumbo park={park} />
            </Swiper.Item>
          ) : (
            range(1).map((item, index) => (
              <Swiper.Item key={index}>
                <Jumbo />
              </Swiper.Item>
            ))
          )}
        </Swiper>
      </Section>
      <Section
        title="品牌服务"
        style={{ marginTop: "-1rem" }}
        to={`/${parkName}/empowerment`}
      >
        <Swiper
          slideSize={45}
          indicator={() => {}}
          style={{
            padding: ".5rem",
            boxSizing: "border-box",
            backgroundColor: "var(--adm-color-box)",
          }}
        >
          {empowerments.length > 0
            ? empowerments.map((item) => (
                <Swiper.Item key={item.id}>
                  <div style={{ padding: ".5rem" }}>
                    <Card1 {...item} />
                  </div>
                </Swiper.Item>
              ))
            : range(5).map((item, index) => (
                <Swiper.Item key={index}>
                  <div style={{ padding: ".5rem" }}>
                    <Card1 />
                  </div>
                </Swiper.Item>
              ))}
        </Swiper>
      </Section>
      <Section
        title="品牌交易"
        style={{ marginTop: "-1rem" }}
        to={`/${parkName}/transaction/1`}
      >
        <Tabs onChange={(key) => handleTabChange(key)}>
          {clazzes.map((item, index) => (
            <Tabs.Tab title={item.title} key={item.id}>
              <Grid columns={3} gap={8}>
                {tmgoods.length > 0
                  ? tmgoods.map((item) => (
                      <Grid.Item key={item.id}>
                        <Card2 {...item} />
                      </Grid.Item>
                    ))
                  : range(6).map((item, index) => (
                      <Grid.Item key={index}>
                        <Card2 />
                      </Grid.Item>
                    ))}
              </Grid>
            </Tabs.Tab>
          ))}
        </Tabs>
      </Section>
      <Section title="环球视角" to={`/${parkName}/news/1`}>
        <List>
          {isRecommendPosts.map((item) => (
            <List.Item key={item.id} arrow={false} clickable>
              <Blog1 post={item} />
            </List.Item>
          ))}
        </List>
      </Section>
      <BottomBar />
    </>
  );
}
