import supabase from "../..";
export const reqLists = async (com_id, listName, page = 1) => {
  const query = supabase
    .from(listName)
    .select("*", {
      count: "exact",
    })
    .eq("company_id", com_id);
  const start = (page - 1) * 20;
  const end = start + 19;

  const { data, count, error } = await query.range(start, end);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

export const reqInfo = async (
  parkId,
  id,
  listName,
  selectIdName,
  innerTabel
) => {
  const { data, count, error } = await supabase
    .from(listName)
    .select(`*,${innerTabel}(*)`, {
      count: "exact",
    })
    .eq("park_id", parkId)
    .eq(selectIdName, id);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
