import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NavBar, Tabs, List, Tag, Mask, SpinLoading } from "antd-mobile";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { reqRiskLists } from "../../../service/me/monitoring";
import { clazzes } from "../../../intlClass";
export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const { search, pathname } = location;

  const params = useParams();
  const parkName = pathname.split("/").filter(Boolean)[0];
  const [visible, setVisible] = useState(true);
  const [abnormal, setAbnormal] = useState([]);
  const [riskTm, setRiskTm] = useState([]);
  const [riskPt, setRiskPt] = useState([]);
  const [navBarIndex, setNavBarIndex] = useState(params.id ?? "1");

  const onChange = (val) => {
    setNavBarIndex(val);
    const result =
      pathname.slice(0, pathname.lastIndexOf("/")) + "/" + val + search;
    navigate(result, { replace: true });
    fetchRisks(val);
    return;
  };
  const fetchRisks = (val) => {
    if (search) {
      setVisible(true);
      const com_id = search.split("=")[1];
      switch (val) {
        case "1":
          reqRiskLists(com_id, "abnormal_items").then((res) => {
            setAbnormal(res.data);
            setVisible(false);
          });
          break;
        case "2":
          reqRiskLists(com_id, "risk_trademark").then((res) => {
            setRiskTm(res.data);
            setVisible(false);
          });
          break;
        case "3":
          reqRiskLists(com_id, "risk_patent").then((res) => {
            setRiskPt(res.data);
            setVisible(false);
          });
          break;
        default:
          return;
      }
    }
  };

  useEffect(() => {
    if (search) {
      setNavBarIndex(params.id);
      fetchRisks(navBarIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, location]);

  return (
    <>
      <NavBar onBack={() => navigate(-1)}>监测预警</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <Tabs activeKey={navBarIndex} onChange={onChange} className={styles.tabs}>
        <Tabs.Tab title="经营风险" key="1">
          <List
            header={
              <div className={styles.header}>
                共有
                {abnormal.length > 0
                  ? JSON.parse(abnormal[0].abnormal_items).length
                  : 0}
                条经营风险
              </div>
            }
          >
            {abnormal.length > 0 &&
              JSON.parse(abnormal[0].abnormal_items).map((item, index) => (
                <List.Item
                  key={index}
                  description={item.in_date}
                  extra={
                    item.is_history === 0 ? (
                      <Tag color="red">高风险</Tag>
                    ) : (
                      <Tag color="green">低风险</Tag>
                    )
                  }
                  onClick={() =>
                    navigate(
                      `/${parkName}/me/monitoring/risk/${search.split("=")[1]
                      }?${index + 1}`
                    )
                  }
                  clickable
                >
                  {item.in_reason}
                </List.Item>
              ))}
          </List>
        </Tabs.Tab>
        <Tabs.Tab title="商标监测" key="2">
          <List
            header={
              <div className={styles.header}>
                共有
                {riskTm.length > 0
                  ? JSON.parse(riskTm[0].risk_trademark).length
                  : 0}
                件商标
              </div>
            }
          >
            {riskTm.length > 0 &&
              JSON.parse(riskTm[0].risk_trademark).map((item, index) => (
                <List.Item
                  key={index}
                  prefix={
                    <img
                      className={styles.image}
                      src={item.image_url}
                      alt="商标图形"
                    />
                  }
                  description={`第${item.type_num}类-${clazzes[Number.parseInt(item.type_num) - 1].title
                    }`}
                  extra={<Tag color="warning">待续展</Tag>}
                  onClick={() =>
                    navigate(
                      `/${parkName}/me/monitoring/trademark/${search.split("=")[1]
                      }?${index + 1}`
                    )
                  }
                  clickable
                >
                  {item.name}
                </List.Item>
              ))}
          </List>
        </Tabs.Tab>
        <Tabs.Tab title="专利监测" key="3">
          <List
            header={
              <div className={styles.header}>
                共有
                {riskPt.length > 0
                  ? JSON.parse(riskPt[0].risk_patent).length
                  : 0}
                件专利
              </div>
            }
          >
            {riskPt.length > 0 &&
              JSON.parse(riskPt[0].risk_patent).map((item, index) => (
                <List.Item
                  key={index}
                  extra={<Tag color="warning">待缴年费</Tag>}
                  onClick={() =>
                    navigate(
                      `/${parkName}/me/monitoring/patent/${search.split("=")[1]
                      }?${index + 1}`
                    )
                  }
                  clickable
                >
                  {item.patent_name}
                </List.Item>
              ))}
          </List>
        </Tabs.Tab>
      </Tabs>
    </>
  );
}
