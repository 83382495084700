import { Button, Skeleton } from "antd-mobile";
import styles from "./Card2.module.css";
import { clazzes } from "../../intlClass";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context";

export default function Card2({
  id,
  request_no,
  mark_name,
  good_kind,
  tm_price,
}) {
  const navigate = useNavigate();
  const { parkName } = useContext(UserContext);
  const handleOnclick = (id, request_no, good_kind) => {
    navigate(
      `/${parkName}/transaction/details/${id}?request_no=${request_no}&good_kind=${good_kind}`
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {!request_no ? (
          <Skeleton animated style={{ width: "100%", height: "5rem" }} />
        ) : (
          <img
            className={styles.img}
            src={`https://biaoxq-oss.oss-cn-beijing.aliyuncs.com/${request_no}.jpg`}
            alt="封面图"
          />
        )}
      </div>
      {id ? (
        <div className={styles.body}>
          <div className={styles.primary}>{mark_name}</div>
          <div className={styles.secondary}>
            {"第" + good_kind + "类-" + clazzes[good_kind - 1]?.title}
          </div>
        </div>
      ) : (
        <Skeleton.Paragraph lineCount={3} animated />
      )}

      <div className={styles.footer}>
        <Button
          color="primary"
          size="small"
          onClick={() => handleOnclick(id, request_no, good_kind)}
          block
        >
          面议
        </Button>
      </div>
    </div>
  );
}
