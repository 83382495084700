import { Avatar } from "antd-mobile";
import classnames from "classnames";
import { PaperClipOutlined } from "@ant-design/icons";
import styles from "./ChatBubble.module.css";
import { Link } from "react-router-dom";
import { reqDown } from "../service/me";
export default function ChatBubble({
  nickname,
  content,
  attachments,
  timestamp,
  reverse,
}) {
  const handleDown = (e, name, oldName) => {
    e.preventDefault();
    reqDown(name).then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = oldName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <div className={classnames(styles.root, { [styles.reverse]: reverse })}>
      <Avatar src="" />
      <div className={styles.wrapper}>
        <div className={styles.header}>{nickname}</div>
        <div className={styles.body}>
          <div className={styles.arrow} />
          <div className={styles.content}>{content}</div>
          {/* <Divider /> */}
          {attachments.length > 0 && (
            <div className={styles.attachments}>
              {attachments.map((item) => (
                <Link
                  onClick={(e) => handleDown(e, item.file_name, item.old_name)}
                  key={item.id}
                  to={item.href}
                >
                  <PaperClipOutlined style={{ marginRight: ".25rem" }} />
                  {item.old_name}
                </Link>
              ))}
            </div>
          )}
        </div>
        <div className={styles.footer}>{timestamp}</div>
      </div>
    </div>
  );
}
