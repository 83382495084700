import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavBar, Mask, SpinLoading, List, Tag, Footer } from "antd-mobile";
import styles from "./index.module.css";
import { getNoticeList } from "../../../service/me/notice";
import { MessageOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context";

export default function Index() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  // const { parkId } = useContext(UserContext);
  const location = useLocation();
  const { search, pathname } = location;
  const parkName = pathname.split("/").filter(Boolean)[0];

  const { state } = useLocation();
  const parkId = state?.parkId;

  // 消息列表数据 
  const [noticeList, setNoticeList] = useState([]);
  const com_id = search.split("=")[1];

  const getNoticeData = async () => {
    const res = await getNoticeList(com_id, parkId);
    // console.log(res, '消息列表数据');
    setNoticeList(res.data);
    setVisible(false);
  }

  useEffect(() => {
    getNoticeData();
  }, [])

  return (
    <>
      <NavBar onBack={() => navigate(-1)}>消息通知</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <List
        header={
          <div className="styles.header">
            共有
            {noticeList.length}
            条消息通知
          </div>
        }
      >
        {noticeList.length > 0 &&
          noticeList.map((item, index) => (
            <List.Item
              key={index}
              // description={new Date(item.broadcast_time).toLocaleDateString()} // 使用 description 来显示通知时间
              extra={
                item.is_read === 0 ? (
                  <Tag color="red">未读</Tag> // 当 is_read 为 0 时显示“未读”标签
                ) : null
              }
              onClick={() => navigate(`/${parkName}/me/notice/detail/${search.split("=")[1]}`, { state: { item } })} // 点击跳转到详细通知页面，并且传递 item 数据
              clickable
            >
              <div className={styles.noticeItem}>
                <MessageOutlined style={{ marginRight: '1rem', fontSize: '2.4rem', color: 'rgba(0, 0, 0, 0.5)' }} /> {/* 图标 */}
                <div className={styles.info}>
                  <div className={styles.title}>{item.title}</div> {/* 通知标题 */}
                  <div className={styles.date}>
                    {new Date(item.broadcast_time).toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric' })}
                  </div> {/* 通知时间 */}
                </div>
              </div>
            </List.Item>
          ))}
      </List>
      <Footer style={{ padding: "1rem 0 3rem 0" }} content="~ 到底了 ~" />
    </>
  )
}
