import { Skeleton } from "antd-mobile";
import styles from "./Jumbo.module.css";

export default function Jumbo({ park }) {
  return (
    <div className={styles.root}>
      {!park ? (
        <Skeleton animated className={styles.background} />
      ) : (
        <img className={styles.background} src={park.bg_img_url} alt="背景图" />
      )}
      <div className={styles.content}>
        <div className={styles.img_box}>
          {!park ? (
            <Skeleton
              className={styles.logo}
              style={{ width: "50%", margin: "0 auto" }}
            />
          ) : (
            <img
              className={styles.logo}
              src={park.logo_img_url}
              alt="园区logo"
            />
          )}
        </div>
        <div className={styles.primary}>
          {park?.park_role ?? "品牌服务平台"}
        </div>
        <div className={styles.secondary}>
          {park?.sub_title ?? "为您的品牌保驾护航"}
        </div>
      </div>
    </div>
  );
}
