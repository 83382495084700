import supabase from "..";

// 获取所有服务列表
export const reqEmpowerment = async () => {
  const { data, error } = await supabase
    .from("brand_empowerment")
    .select(`*`, { count: "exact" });

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

// 获取服务详情
export const reqEmpowermentInfo = async (id) => {
  const { data, error } = await supabase
    .from("brand_empowerment")
    .select()
    .eq("id", id);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

// 插入请求
export const IsqEmpowerment = async (
  queryInfo,
  company_name,
  userId,
  brand_id,
  parkId,
  id
) => {
  const { data, error } = await supabase
    .from("order")
    .insert([
      {
        ...queryInfo,
        user_id: userId,
        company_name: company_name,
        brand_id: brand_id,
        park_id: parkId,
        status: 0,
        goods_id: id,
      },
    ])
    .select();

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

// 插入评论
export const reqInsComment = async (
  content,
  parkId,
  orderId,
  role_flag = 1
) => {
  const { data, error, count } = await supabase
    .from("comment")
    .insert([
      {
        content: content,
        order_id: orderId,
        park_id: parkId,
        role_flag: role_flag,
      },
    ])
    .select();

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
