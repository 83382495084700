import { Button, Form, Input, NavBar, Toast } from "antd-mobile";
import styles from "./index.module.css";
import { reqPark } from "../../service/home/login";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { reqRecoveryPassword } from "../../service/register";
export default function Recovery() {
  const location = useLocation();
  const parkName = location.pathname.split("/").filter(Boolean)[0];
  const [countdown, setCountdown] = useState(0);
  const [verfiy, setVerfiy] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [park, setPark] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // 跳转邮箱
  const EmailOfficialWebsite = () => {
    const email = form.getFieldValue("email");
    const domain = email.substring(email.lastIndexOf("@") + 1);
    const emailWebsites = {
      "gmail.com": "https://mail.google.com/",
      "qq.com": "https://mail.qq.com/",
      "163.com": "https://mail.163.com/",
    };
    const WebsiteLink = emailWebsites[domain];

    if (WebsiteLink) {
      window.open(WebsiteLink, "_blank");
    } else {
      const searchLink = `https://www.baidu.com/s?wd=${encodeURIComponent(
        domain + "邮箱官网"
      )}`;
      window.open(searchLink, "_blank");
    }
    return null;
  };
  const onFinish = (value) => {
    Toast.show({
      icon: "loading",
      duration: 0,
    });
    setDisabled(true);

    reqRecoveryPassword(value.email, parkName, window.location.host).then(
      (res) => {
        Toast.show({
          icon: "success",
          content: "发送成功",
        });
        setCountdown(30);
        setVerfiy(false);
      }
    );
  };

  useEffect(() => {
    if (parkName) {
      reqPark(parkName).then((res) => {
        if (res.data.length > 0) {
          setPark(res.data[0]);
        }
      });
    }
  }, [parkName]);
  useEffect(() => {
    let timer;

    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      setDisabled(false);
    }

    return () => clearTimeout(timer);
  }, [countdown]);
  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <NavBar onBack={() => navigate(-1)}>找回密码</NavBar>
        <div className={styles.header}>
          <img src={park && park.img_url} alt="封面图" />
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          footer={
            <>
              <Button
                disabled={disabled}
                style={{ marginBottom: "2rem" }}
                type="sumbit"
                color="primary"
                block
              >
                发送邮件
                <span style={{ position: "absolute", right: "1rem" }}>
                  {countdown > 0 ? `${countdown}...` : ""}
                </span>
              </Button>

              <Button
                disabled={verfiy}
                color="primary"
                block
                onClick={() => EmailOfficialWebsite()}
              >
                立即验证
              </Button>
            </>
          }
        >
          <Form.Header>验证邮箱</Form.Header>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "邮箱不能为空" },
              {
                type: "email",
                message: "邮箱格式不正确",
                validateTrigger: ["onBlur"],
              },
            ]}
            label={
              <span>
                <MailOutlined style={{ marginRight: ".5rem" }} />
                邮箱
              </span>
            }
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
