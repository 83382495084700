import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  NavBar,
  Tabs,
  List,
  Mask,
  SpinLoading,
  InfiniteScroll,
} from "antd-mobile";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { reqLists } from "../../../service/me/property";
import { clazzes } from "../../../intlClass";

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const { search, pathname } = useLocation();
  const params = useParams();
  const com_id = search.split("=")[1];
  const parkName = pathname.split("/").filter(Boolean)[0];
  const [visible, setVisible] = useState(true);

  const [trades, setTrades] = useState([]);
  const [tradesCounts, setTradesCounts] = useState(0);
  const [tradesPage, setTradesPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [pts, setPts] = useState([]);
  const [ptsCounts, setPtsCounts] = useState(0);
  const [ptsPage, setPtsPage] = useState(1);
  const [ptshasMore, setptsHasMore] = useState(true);

  const [cps, setCps] = useState([]);
  const [cpsCounts, setCpsCounts] = useState(0);
  const [cpsPage, setCpsPage] = useState(1);
  const [cpshasMore, setcpsHasMore] = useState(true);

  const [cpts, setCpts] = useState([]);
  const [cptsCounts, setCptsCounts] = useState(0);
  const [cptsPage, setCptsPage] = useState(1);
  const [cptshasMore, setcptsHasMore] = useState(true);

  const [navBarIndex, setNavBarIndex] = useState(params.id ?? "1");
  const tradeloadMore = async (page) => {
    const append = await reqLists(com_id, "trademark", page);
    setTradesCounts(append.count);
    setTrades((val) => [...val, ...append.data]);
    setHasMore(append.data.length === 20);
    setTradesPage(page + 1);
    setVisible(false);
  };

  const ptsloadMore = async (page) => {
    const append = await reqLists(com_id, "patent", page);
    setPtsCounts(append.count);
    setPts((val) => [...val, ...append.data]);
    setptsHasMore(append.data.length === 20);
    setPtsPage(page + 1);
    setVisible(false);
  };
  const cpsloadMore = async (page) => {
    const append = await reqLists(com_id, "copyright", page);
    setCpsCounts(append.count);
    setCps((val) => [...val, ...append.data]);
    setcpsHasMore(append.data.length === 20);
    setCpsPage(page + 1);
    setVisible(false);
  };
  const cptsloadMore = async (page) => {
    const append = await reqLists(com_id, "copyright_soft", page);
    setCptsCounts(append.count);
    setCpts((val) => [...val, ...append.data]);
    setcptsHasMore(append.data.length === 20);
    setCptsPage(page + 1);
    setVisible(false);
  };

  const onChange = (val) => {
    setNavBarIndex(val);
    const result =
      pathname.slice(0, pathname.lastIndexOf("/")) + "/" + val + search;
    navigate(result, { replace: true });
    return;
  };
  const filterType = (typeName) => {
    switch (typeName) {
      case "中国实用新型专利":
        return "/assets/icon/ico-patent-1.png";
      case "中国发明专利":
        return "/assets/icon/ico-patent-2.png";
      case "中国外观设计专利":
        return "/assets/icon/ico-patent-3.png";
      default:
        return;
    }
  };
  useEffect(() => {
    if (search) {
      setNavBarIndex(params.id);
    }
  }, [search, location, params.id]);
  return (
    <>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <NavBar onBack={() => navigate(-1)}>知识产权</NavBar>
      <Tabs className={styles.tabs} activeKey={navBarIndex} onChange={onChange}>
        <Tabs.Tab title="商标" key="1">
          <List
            style={{ "--extra-max-width": "30%" }}
            header={
              <div className={styles.header}>共有{tradesCounts}件商标</div>
            }
          >
            {trades.map((item, index) => (
              <List.Item
                key={item.id_tm}
                prefix={
                  <img
                    className={styles.image}
                    src={item.image_url}
                    alt="商标图形"
                  />
                }
                description={`第${item.type_num}类-${
                  clazzes[Number.parseInt(item.type_num) - 1].title
                }`}
                extra={item.status}
                onClick={() =>
                  navigate(`/${parkName}/me/property/trademark/${item.id_tm}`)
                }
                clickable
              >
                {item.name}
              </List.Item>
            ))}
            <InfiniteScroll
              loadMore={() => tradeloadMore(tradesPage)}
              hasMore={hasMore}
            />
          </List>
        </Tabs.Tab>
        <Tabs.Tab title="专利" key="2">
          <List
            style={{ "--extra-max-width": "30%" }}
            header={<div className={styles.header}>共有{ptsCounts}件专利</div>}
          >
            {pts.map((item) => (
              <List.Item
                prefix={
                  <img
                    className={styles.icon}
                    src={filterType(item.type_name)}
                    alt="专利类型"
                  />
                }
                key={item.id_pt}
                description={item.last_status}
                onClick={() =>
                  navigate(`/${parkName}/me/property/patent/${item.id_pt}`)
                }
                clickable
              >
                {item.patent_name}
              </List.Item>
            ))}
            <InfiniteScroll
              loadMore={() => ptsloadMore(ptsPage)}
              hasMore={ptshasMore}
            />
          </List>
        </Tabs.Tab>
        <Tabs.Tab title="著作权" key="3">
          <List
            style={{ "--extra-max-width": "30%" }}
            header={
              <div className={styles.header}>共有{cpsCounts}件著作权</div>
            }
          >
            {cps.map((item) => (
              <List.Item
                prefix={
                  <img
                    className={styles.icon}
                    src="/assets/icon/ico-copyright-1.png"
                    alt="专利类型"
                  />
                }
                key={item.id_cp}
                description={item.type_name}
                onClick={() =>
                  navigate(`/${parkName}/me/property/copyright/${item.id_cp}`, {
                    replace: true,
                  })
                }
                clickable
              >
                {item.name}
              </List.Item>
            ))}
            <InfiniteScroll
              loadMore={() => cpsloadMore(cpsPage)}
              hasMore={cpshasMore}
            />
          </List>
        </Tabs.Tab>
        <Tabs.Tab title="软件著作权" key="4">
          <List
            style={{ "--extra-max-width": "30%" }}
            header={
              <div className={styles.header}>共有{cptsCounts}件软件著作权</div>
            }
          >
            {cpts.map((item) => (
              <List.Item
                prefix={
                  <img
                    className={styles.icon}
                    src="/assets/icon/ico-copyright-2.png"
                    alt="专利类型"
                  />
                }
                key={item.id_cs}
                onClick={() =>
                  navigate(
                    `/${parkName}/me/property/software-copyright/${item.id_cs}`
                  )
                }
                clickable
              >
                {item.name}
              </List.Item>
            ))}
            <InfiniteScroll
              loadMore={() => cptsloadMore(cptsPage)}
              hasMore={cptshasMore}
            />
          </List>
        </Tabs.Tab>
      </Tabs>
    </>
  );
}
