import supabase from "..";

// 选择公司列表
export const reqCompanies = async (parkId, page = 1, keywords) => {
  const query = supabase
    .from("company")
    .select("format_name,id", { count: "exact" })
    .eq("park_id", parkId);

  const start = (page - 1) * 20;
  const end = start + 19;
  if (keywords) {
    query.or(`format_name.like.%${keywords}%`);
  }
  const { data, count, error } = await query.range(start, end);

  if (error) {
    console.error("获取数据失败:", error.message);
  }

  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
// 插入公司
export const reqInsCompany = async (userId, queryInfo) => {
  const { data, error } = await supabase
    .from("user_company_park")
    .insert([{ ...queryInfo, user_id: userId }])
    .select();

  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 删除公司
export const reqDelCompany = async (userId, company_id) => {
  const { data, error } = await supabase
    .from("user_company_park")
    .delete()
    .eq("user_id", userId)
    .eq("company_id", company_id)
    .select();
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 更改公司信息
export const reqUpCompany = async (userId, company_id, queryInfo) => {
  const { data, error } = await supabase
    .from("user_company_park")
    .update(queryInfo)
    .eq("user_id", userId)
    .eq("company_id", company_id)
    .select();

  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 查询已经关注的公司详情
export const reqCompany = async (userId, company_id) => {
  const { data, count } = await supabase
    .from("user_company_park")
    .select(`name,address,duty,tel,email,com_name`, {
      count: "exact",
    })
    .eq("user_id", userId)
    .eq("company_id", company_id);

  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
