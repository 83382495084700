import supabase from "..";
export const reqTmGoods = async (pageSize, current, good_kind) => {
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("tm_goods")
    .select(
      "id,request_no,mark_name,good_kind,group_cn,group_id,registration_date",
      { count: "exact" }
    )
    .neq("tm_status", 0);
  if (good_kind) {
    query.eq("good_kind", good_kind);
  }
  const { data, count, error } = await query.range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

export const reqTmgoodInfo = async (tmgoodId) => {
  let query = supabase
    .from("tm_goods")
    .select(
      "id,request_no,mark_name,good_kind,group_cn,group_id,registration_date,shape_flag,char_num,goods_status"
    )
    .eq("id", tmgoodId);
  const { data, error } = await query;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  return {
    data: data ?? [],
    error: error,
  };
};
