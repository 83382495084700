import { shRequest } from ".";

export const DemoreqInsertUser = (queryInfo) => {
  const userInfo = {
    name: queryInfo.name,
    phone: queryInfo.tel,
    mail: queryInfo.email,
    park_id: queryInfo.park_id,
    short_name: queryInfo.short_name,
    company_id: queryInfo.company_id,
    duty: queryInfo.duty,
    company_name: queryInfo.com_name,
  };
  return shRequest.post({
    url: "/insertUser",
    data: userInfo,
  });
};
