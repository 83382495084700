import { NavBar, List, Footer, Mask, SpinLoading } from "antd-mobile";
import styles from "./index.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../context";
import { reqInfo } from "../../../service/me/property";

export default function Copyright() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { parkId } = useContext(UserContext);
  const [cpInfo, setCpInfo] = useState({});
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    if (parkId) {
      const id = pathname.split("/").pop();
      reqInfo(parkId, id, "park_copyright", "copyright_id", "copyright").then(
        (res) => {
          if (res.data.length > 0) {
            setCpInfo(res.data[0].copyright);
          }
          setVisible(false);
        }
      );
    }
  }, [pathname, parkId]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>著作权</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <List header="基本信息">
        <List.Item title="名称">{cpInfo.name}</List.Item>
        <List.Item title="登记号">{cpInfo.number}</List.Item>
        <List.Item
          title="作品分类"
          prefix={
            <img
              className={styles.icon}
              src="/assets/icon/ico-copyright-1.png"
              alt="专利类型"
            />
          }
        >
          {cpInfo.type_name}
        </List.Item>
        <List.Item title="创作时间">{cpInfo.success_date}</List.Item>
        <List.Item title="发布时间">{cpInfo.first_date}</List.Item>
        <List.Item title="登记人">{cpInfo.company}</List.Item>
        <List.Item title="登记时间">{cpInfo.approval_date}</List.Item>
      </List>
      <Footer style={{ padding: "1rem 0 3rem 0" }} content="~ 到底了 ~" />
    </>
  );
}
