import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./details.module.css";
import {
  Button,
  Image,
  List,
  Mask,
  NavBar,
  SafeArea,
  Space,
  SpinLoading,
} from "antd-mobile";
import { reqTmgoodInfo } from "../../service/transaction";
import { clazzes, trademarkStyle } from "../../intlClass";
import dayjs from "dayjs";
import { UserContext } from "../../context";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export default function TransactionDetails() {
  const { search } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { parkName } = useContext(UserContext);
  const [tmInfo, setTmInfo] = useState();
  const [visible, setVisible] = useState(true);
  const companiesTableConfig = {
    nameList: [
      {
        title: "注册号",
        dataIndex: "request_no",
      },
      {
        title: "商标样式",
        dataIndex: "shape_flag",
      },
      {
        title: "最低报价",
        dataIndex: "tm_price",
      },
      {
        title: "商品/服务",
        dataIndex: "group_cn",
      },
      {
        title: "关联群组",
        dataIndex: "group_id",
      },
      {
        title: "注册日期",
        dataIndex: "registration_date",
      },
    ],
  };
  useEffect(() => {
    if (search) {
      reqTmgoodInfo(id).then((res) => {
        setTmInfo(res.data[0]);
        setVisible(false);
      });
    }
  }, [search, id]);
  return (
    <div className={styles.root}>
      <NavBar onBack={() => navigate(-1)}>商标详情</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      {tmInfo && (
        <List header="基本信息">
          <List.Item title="名称">{tmInfo.mark_name}</List.Item>
          <List.Item
            title="商标类别"
            prefix={
              <Image
                width={64}
                style={{ border: "1px solid #ccc", margin: "1rem 0" }}
                className={styles.feature}
                src={`https://biaoxq-oss.oss-cn-beijing.aliyuncs.com/${tmInfo.request_no}.jpg`}
              />
            }
          >
            {"第" +
              tmInfo.good_kind +
              "类-" +
              clazzes[tmInfo.good_kind - 1].title}
          </List.Item>
          {companiesTableConfig.nameList.map((item, index) => (
            <List.Item title={item.title} key={index}>
              {(() => {
                if (item.dataIndex === "registration_date") {
                  return tmInfo[item.dataIndex]
                    ? dayjs.utc(tmInfo[item.dataIndex]).format("YYYY年MM月DD日")
                    : "-";
                } else if (item.dataIndex === "tm_price") {
                  return "面议";
                } else if (item.dataIndex === "shape_flag") {
                  return trademarkStyle[tmInfo[item.dataIndex] - 1]?.trademark;
                } else {
                  return tmInfo[item.dataIndex];
                }
              })()}
            </List.Item>
          ))}
        </List>
      )}
      <div className={styles.actions}>
        <Space direction="vertical" block>
          <Button
            color="primary"
            onClick={() =>
              navigate(`/${parkName}/empowerment/create/8?id=${id}`)
            }
            block
          >
            立即咨询
          </Button>
          <Button color="primary" fill="outline" block>
            咨询电话：400-006-2018
          </Button>
        </Space>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}
