import { Button, Form, Input, Toast, NavBar } from "antd-mobile";
import styles from "./index.module.css";
import { reqPark } from "../../service/home/login";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { reqUpdateUser } from "../../service/register";
export default function Renew() {
  const location = useLocation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const parkName = location.pathname.split("/").filter(Boolean)[0];
  const [park, setPark] = useState();

  const success = (message) => {
    Toast.show({
      icon: "success",
      content: message,
      afterClose: goLogin,
    });
  };

  const goLogin = () => {
    navigate(`/${parkName}/login`);
  };
  const resetPassword = async ({ newPassword }) => {
    const email = JSON.parse(
      localStorage.getItem("sb-zevgwqoenxkhkvornmdw-auth-token")
    ).user.email;
    await reqUpdateUser(email, newPassword).then(() => {
      success("密码重置成功");
    });
  };

  useEffect(() => {
    if (parkName) {
      reqPark(parkName).then((res) => {
        if (res.data.length > 0) {
          setPark(res.data[0]);
        }
      });
    }
  }, [parkName]);

  // 密码校验
  const validatePassword = (_, value) => {
    if (!value || form.getFieldValue("newPassword") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("两次输入的密码不一致"));
  };
  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <NavBar onBack={() => navigate(-1)}>重置密码</NavBar>

        <div className={styles.header}>
          <img src={park && park.img_url} alt="封面图" />
        </div>
        <Form
          form={form}
          onFinish={resetPassword}
          footer={
            <Button type="submit" color="primary" block>
              立即修改
            </Button>
          }
        >
          <Form.Header>重置密码</Form.Header>
          <Form.Item
            name="newPassword"
            label="新密码"
            rules={[{ required: true, message: "新密码不能为空" }]}
          >
            <Input type="password" placeholder="请输入新的密码" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="确认密码"
            rules={[
              { required: true, message: "两次输入的密码不一致" },
              { validator: validatePassword },
            ]}
          >
            <Input type="password" placeholder="请再输入一遍新的密码" />
          </Form.Item>
        </Form>
        {/* <div className={styles.login}>
          <div className={styles.title}>更改密码</div>
          <div className={styles.welcome}>
            <div>请输入需要重置密码的邮箱</div>
          </div>
          <Form
            form={form}
            onFinish={resetPassword}
            footer={
              <Button type="submit" color="primary" block>
                立即修改
              </Button>
            }
          >
            <Form.Item
              name="newPassword"
              label="新密码"
              rules={[{ required: true, message: "新密码不能为空" }]}
            >
              <Input type="password" placeholder="请输入新的密码" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="确认密码"
              rules={[
                { required: true, message: "两次输入的密码不一致" },
                { validator: validatePassword },
              ]}
            >
              <Input type="password" placeholder="请再输入一遍新的密码" />
            </Form.Item>
          </Form>
        </div> */}
      </div>
    </div>
  );
}
