import { useState } from "react";
import { reqTmGoods } from "../../service/transaction";
import { Grid, InfiniteScroll } from "antd-mobile";
import { Card2 } from "../../components/Card";

export default function TmgoodsList({ good_kind }) {
  const [tmgoods, setTmgoods] = useState([]);
  const [current, setCurrnet] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loadMore = async (pageSize, current, good_kind) => {
    const append = await reqTmGoods(pageSize, current, good_kind);
    setTmgoods((val) => [...val, ...append.data]);
    setHasMore(append.data.length === 24);
    setCurrnet(current + 1);
  };
  return (
    <>
      <Grid columns={3} gap={8}>
        {tmgoods.map((item) => (
          <Grid.Item key={item.id}>
            <Card2 {...item} />
          </Grid.Item>
        ))}
      </Grid>
      <InfiniteScroll
        loadMore={() => loadMore(24, current, good_kind)}
        hasMore={hasMore}
      />
    </>
  );
}
