import { NavBar, Grid, SpinLoading, Mask } from "antd-mobile";
import Section from "../../components/Section";
import { Card1 } from "../../components/Card";
import BottomBar from "../../components/BottomBar";
import { useEffect, useState } from "react";
import { reqEmpowerment } from "../../service/empowerment";

import { range } from "lodash";

export default function Index() {
  const [empowerments, setEmpowerments] = useState([]);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    reqEmpowerment().then((res) => {
      setEmpowerments(res.data);
      setVisible(false);
    });
  }, []);
  return (
    // <>
    //   {visible ? (
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         width: "100%",
    //         height: "100vh",
    //         background: "#ffffff",
    //       }}
    //     >
    //       <SpinLoading style={{ "--size": "48px", margin: "auto" }} />
    //     </div>
    //   ) : (
    <>
      <Mask visible={visible} color="white">
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <NavBar backArrow={false}>品牌服务</NavBar>
      <Section
        style={{
          backgroundColor: "#F5F5F5",
          padding: ".5rem 1rem 1rem 1rem",
        }}
      >
        <Grid columns={2} gap={16}>
          {empowerments.length > 0
            ? empowerments.map((item) => (
                <Grid.Item key={item.id}>
                  <Card1 {...item} />
                </Grid.Item>
              ))
            : range(16).map((index) => (
                <Grid.Item key={index}>
                  <Card1 />
                </Grid.Item>
              ))}
        </Grid>
      </Section>
      <BottomBar />
    </>
  );
}
// </>
//   );
// }
