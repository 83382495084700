import { strapiRequest } from "..";
import qs from "qs";

// 获取文章分类
export const reqStrapiPostCategory = () => {
  const postListsQuery = qs.stringify(
    {
      fields: ["category_name"],
      populate: {
        posts: {
          populate: "*",
        },
        sort: ["updatedAt:desc"],
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return strapiRequest.get({
    url: `/post-categories?${postListsQuery}`,
  });
};
// 获取文章列表
export const reqStrapiPosts = (page = 1, parkName, categoryName) => {
  const postQuery = qs.stringify({
    populate: {
      bg_img: {
        fields: ["url"],
      },
    },
    sort: ["post_date:desc"],
    pagination: { page, pageSize: 10 },
    filters: {
      $or: [
        {
          parkName: {
            $eq: parkName,
          },
        },
        {
          isAdministrator: {
            $eq: true,
          },
        },
      ],
      post_category: {
        category_name: {
          $eq: categoryName,
        },
      },
    },
  });
  return strapiRequest.get({
    url: `/posts?${postQuery}`,
  });
};

// 获取文章详情
export const reqStrapiPostInfo = (parkName, postId = 1) => {
  const query = qs.stringify(
    {
      populate: {
        bg_img: {
          fields: ["url"],
        },
      },
      $or: [
        {
          parkName: {
            $eq: parkName,
          },
        },
        {
          isAdministrator: {
            $eq: true,
          },
        },
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );

  return strapiRequest.get({
    url: `/posts/${postId}?${query}`,
  });
};

// 获取推荐文章
export const reqStrapiRecommendPosts = (parkName) => {
  const query = qs.stringify(
    {
      populate: {
        bg_img: {
          fields: ["url"],
        },
      },
      filters: {
        $or: [
          {
            parkName: {
              $eq: parkName,
            },
          },
          {
            isAdministrator: {
              $eq: true,
            },
          },
        ],
        isRecommend: {
          $eq: true,
        },
      },
      sort: ["updatedAt:desc"],
      pagination: { page: 1, pageSize: 5 },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return strapiRequest.get({
    url: `/posts?${query}`,
  });
};
