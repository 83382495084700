import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://zevgwqoenxkhkvornmdw.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inpldmd3cW9lbnhraGt2b3JubWR3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODY1MzU3NTIsImV4cCI6MjAwMjExMTc1Mn0.UdjdfE_8Q4JiUC8KdFYC-zAwJJDrfiO6mlUkEdQKA_U"
);
// const supabase = createClient(
//   "http://192.168.1.196:8000/",
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyAgCiAgICAicm9sZSI6ICJhbm9uIiwKICAgICJpc3MiOiAic3VwYWJhc2UtZGVtbyIsCiAgICAiaWF0IjogMTY0MTc2OTIwMCwKICAgICJleHAiOiAxNzk5NTM1NjAwCn0.dc_X5iR_VP_qT0zsiyj_I_OZ2T9FtRU2BBNWN8Bu4GE"
// );

export default supabase;
