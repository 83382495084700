import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  NavBar,
  Form,
  Input,
  Button,
  Popup,
  CheckList,
  InfiniteScroll,
  SearchBar,
  Space,
  Mask,
  SpinLoading,
  Toast,
} from "antd-mobile";
import styles from "./company.module.css";

import {
  reqCompanies,
  reqCompany,
  reqDelCompany,
  reqInsCompany,
  reqUpCompany,
} from "../../service/company";
import { UserContext } from "../../context";

export default function Company() {
  const location = useLocation();
  const { search } = useLocation();
  const parkName = location.pathname.split("/").filter(Boolean)[0];

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { parkId } = useContext(UserContext);
  const [popupVisible1, setPopupVisible1] = useState(false);
  const [visible, setVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({
    com_name: "",
    name: "",
    address: "",
    duty: "",
    email: "",
    tel: "",
  });
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectCom, setSelectCom] = useState({
    com_name: "",
    name: "",
    address: "",
    duty: "",
    email: "",
    tel: "",
    company_id: "",
  });
  const [keywords, setKeywords] = useState("");
  const { userId } = useContext(UserContext);

  const loadMore = async (parkId, page, keywords) => {
    const append = await reqCompanies(parkId, page, keywords);
    setCompanies((val) => [...val, ...append.data]);
    setHasMore(append.data.length > 0);
    setPage(page + 1);
  };

  const searchRef = useRef(null);
  // 点击列表触发的事件
  const checkListOnChange = async (value) => {
    if (value.length > 0) {
      const filterCom = companies.filter(
        (item) => item.format_name === value.toString()
      );

      setSelectCom((item) => ({
        ...item,
        com_name: filterCom[0].format_name,
        company_id: filterCom[0].id,
      }));
    } else {
      setSelectCom((item) => ({
        ...item,
        com_name: "",
        company_id: "",
      }));
    }
  };

  const searchBarOnSearch = async () => {
    setCompanies([]);
    loadMore(parkId, 1, keywords);
  };

  const searchBarOnChange = async (value) => {
    setKeywords(value);
  };
  const searchBarOnClear = async (value) => {
    setKeywords("");
    setCompanies([]);
    loadMore(parkId, 1);
  };

  // 字段更改
  const onValuesChange = (changedFields, allFields) => {
    setSelectCom((item) => ({
      ...item,
      ...allFields,
    }));
  };
  // 提交按钮
  const onFinish = (value) => {
    if (!search) {
      reqCompany(userId, selectCom.company_id)
        .then((res) => {
          if (res.data.length > 0) {
            return Promise.reject("已经存在");
          }
        })
        .then(() => {
          reqInsCompany(userId, selectCom).then((res) => {
            if (res.data.length > 0) {
              Toast.show({
                icon: "success",
                content: "添加成功",
              });
              navigate(
                `/${parkName}/me/company?com_id=${res.data[0].company_id}`
              );
            }
          });
        })
        .catch((error) => {
          Toast.show({
            icon: "fail",
            content: "公司已存在",
          });
        });
    } else {
      const comId = search.split("=").filter(Boolean)[1];
      reqUpCompany(userId, comId, value).then((res) => {
        if (res.data.length > 0) {
          Toast.show({
            icon: "success",
            content: "修改成功",
          });
        }
      });
    }
  };
  // 删除按钮
  const handleDel = () => {
    const comId = search.split("=").filter(Boolean)[1];
    reqDelCompany(userId, comId).then((res) => {
      if (res.data.length > 0) {
        Toast.show({
          icon: "success",
          content: "删除成功",
        });
        navigate(`/${parkName}/me`);
      }
    });
  };
  useEffect(() => {
    if (userId && search) {
      const comId = search.split("=").filter(Boolean)[1];
      setVisible(true);
      reqCompany(userId, comId).then((res) => {
        setCompany(res.data[0]);
        form.setFieldsValue(res.data[0]);
        setVisible(false);
      });
    }
  }, [userId, form, search]);

  return (
    <>
      <NavBar onBack={() => navigate(-1)}>我的公司</NavBar>
      <Form
        initialValues={search ? company : selectCom}
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        footer={
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button
              disabled={company.com_name === "" && selectCom.com_name === ""}
              type="submit"
              color="primary"
              block
            >
              立即提交
            </Button>
            {search && (
              <Button color="danger" onClick={() => handleDel()} block>
                删除公司
              </Button>
            )}
          </Space>
        }
      >
        <Form.Header>公司</Form.Header>
        <Form.Item
          label="公司名称"
          onClick={() => setPopupVisible1(company.com_name ? false : true)}
        >
          {search ? company.com_name : selectCom.com_name}
          <Popup
            visible={popupVisible1}
            position="right"
            bodyStyle={{ width: "80vw" }}
            onMaskClick={() => setPopupVisible1(false)}
          >
            <div className={styles.popup}>
              <NavBar
                backArrow={false}
                back={<div className={styles.popupBtn}>关闭</div>}
                onBack={() => setPopupVisible1(false)}
                right={
                  <div
                    onClick={() => setPopupVisible1(false)}
                    className={`${styles.popupBtn} ${styles.primary}`}
                  >
                    确认
                  </div>
                }
              >
                选择企业
              </NavBar>
              <div className={styles.search}>
                <SearchBar
                  ref={searchRef}
                  placeholder="公司名称"
                  clearable
                  value={keywords}
                  onSearch={(value) => searchBarOnSearch(value)}
                  onClear={(value) => searchBarOnClear(value)}
                  onChange={(value) => searchBarOnChange(value)}
                />
              </div>
              <CheckList
                // defaultValue={[`${state.format_name ?? ""}`]}
                onChange={(value) => checkListOnChange(value)}
              >
                {companies.map((item, index) => (
                  <CheckList.Item key={index} value={item.format_name}>
                    {item.format_name}
                  </CheckList.Item>
                ))}
              </CheckList>
              <InfiniteScroll
                loadMore={() => loadMore(parkId, page, keywords)}
                hasMore={hasMore}
              />
            </div>
          </Popup>
        </Form.Item>
        <Form.Item name="address" label="办公地址">
          <Input placeholder="请输入办公地址" />
        </Form.Item>
        <Form.Header>联系人</Form.Header>
        <Form.Item name="name" label="姓名">
          <Input placeholder="请输入联系人姓名" />
        </Form.Item>
        <Form.Item name="duty" label="职务">
          <Input placeholder="请输入联系人职务" />
        </Form.Item>
        <Form.Item name="tel" label="联系电话">
          <Input placeholder="请输入联系电话" />
        </Form.Item>
        <Form.Item name="email" label="邮箱">
          <Input placeholder="请输入邮箱" />
        </Form.Item>
      </Form>
      <Mask visible={visible} color="white">
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
    </>
  );
}
