import { Result, Space, Button } from "antd-mobile";
import styles from "./forbidden.module.css";
import { useLocation, useNavigate } from "react-router-dom";
export default function Forbidden() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.state?.referral?.pathname;

  const parkName = pathname?.split("/").filter(Boolean)[0] ?? "";
  const goto = (parkName) => {
    navigate(`/${parkName !== "forbidden" ? parkName : ""}/login`, {
      state: { referral: { pathname: pathname } },
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <Result
          status="error"
          title="很抱歉，你没有访问权限"
          subTitle="请检查你访问的地址是否正确？"
        />
        <Space justify="center" block>
          <Button onClick={() => goto(parkName)} color="success">
            重新登录
          </Button>
          <Button onClick={() => navigate(`/${parkName}/home`)} color="success">
            暂不登陆
          </Button>
        </Space>
      </div>
    </div>
  );
}
