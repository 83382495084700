import { useContext, useState } from "react";
import { InfiniteScroll, List } from "antd-mobile";
import { reqActiviyList } from "../../../../../service/member/activity";
import { UserContext } from "../../../../../context";
import qs from "query-string";
import ActivityCard from "./ActivityCard";
import { useLocation } from "react-router-dom";
export default function ActivityList({ postIndex, status: id }) {
  const { parkId } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [postLists, setPostLists] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { search } = useLocation();
  const loadMore = async (page) => {
    const { member_id } = qs.parse(search);
    const activityData = {
      parkId: parkId,
      status: id,
      page: page,
      member_id: member_id,
    };
    const append = await reqActiviyList(activityData);
    setPostLists((val) => [...val, ...append.data]);
    setHasMore(append.data.length === 10);
    setPage(page + 1);
  };

  return (
    <>
      <List>
        {postLists.map((item, index) => (
          <List.Item key={index} arrow={false}>
            <ActivityCard data={item} />
          </List.Item>
        ))}
        {parkId && (
          <InfiniteScroll loadMore={() => loadMore(page)} hasMore={hasMore} />
        )}
      </List>
    </>
  );
}
