import { useEffect, useContext, useState } from "react";
import {
  NavBar,
  List,
  Form,
  Input,
  TextArea,
  Button,
  Picker,
  Avatar,
  Modal,
  SpinLoading,
  Mask,
  Space,
  Result,
} from "antd-mobile";
import { MobileOutlined, QrcodeOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./create.module.css";
import { UserContext } from "../../context";
import { reqCompanyIds, reqCompanyLists } from "../../service/me";
import {
  reqEmpowermentInfo,
  IsqEmpowerment,
  reqInsComment,
} from "../../service/empowerment";
import qs from "query-string";
import { reqTmgoodInfo } from "../../service/transaction";
import { clazzes } from "../../intlClass";

export default function Create() {
  const navigate = useNavigate();
  const location = useLocation();
  const [pickerVisible, setPickerVisible] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [selectCom, setSelectCom] = useState();
  const [empowermentInfo, setEmpowermentInfo] = useState();
  const { userId, parkId } = useContext(UserContext);
  const [visible, setVisible] = useState(true);
  const [isMask, setMask] = useState(false);
  const [orderId, setOrderId] = useState();
  const { parkName } = useContext(UserContext);
  const [goodInfo, setGoodInfo] = useState();

  const columns = companiesList.map((item) => ({
    label: item.format_name,
    value: item.format_name,
  }));
  const empowermentId = location.pathname.split("/").filter(Boolean).pop();
  const { search } = location;
  const onConfirm = (value) => {
    setSelectCom(value[0]);
    setPickerVisible(false);
  };
  const onFinish = (value) => {
    const { id } = qs.parse(search);
    IsqEmpowerment(value, selectCom, userId, empowermentId, parkId, id)
      .then((res) => {
        if (res.data.length > 0) {
          setMask(true);
          return res.data[0];
        } else {
          return new Promise.reject("添加失败");
        }
      })
      .then((res) => {
        reqInsComment(value.message, parkId, res.id, 1);
        setOrderId(res.id);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  };
  useEffect(() => {
    if (userId && parkId) {
      reqCompanyIds(userId).then((res) => {
        reqCompanyLists(res.data, parkId).then((lists) => {
          setCompaniesList(lists.data);
        });
      });
      reqEmpowermentInfo(empowermentId).then((res) => {
        if (res.data.length > 0) setEmpowermentInfo(res.data[0]);
        setVisible(false);
      });
    }
    if (search) {
      const { id: good_id } = qs.parse(search);
      reqTmgoodInfo(good_id).then((res) => {
        setGoodInfo(res.data[0]);
      });
    }
  }, [userId, parkId, empowermentId, search]);
  return (
    <>
      <Mask color="#FFFFFF" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <Mask color="#FFFFFF" visible={isMask}>
        <div className={styles.mask}>
          <Result
            status="success"
            title="操作成功"
            description="我们的服务顾问将尽快与你联系，请保持手机畅通"
          />
          <Space
            direction="vertical"
            block
            style={{ marginTop: "3rem", "--gap": "1rem" }}
          >
            <Button
              disabled={!orderId}
              color="primary"
              size="large"
              block
              onClick={() =>
                navigate(`/${parkName}/me/consulting/detail/${orderId}`)
              }
            >
              查看订单
            </Button>
            <Button
              size="large"
              onClick={() => navigate(`/${parkName}/empowerment`)}
              block
            >
              其他品牌服务
            </Button>
          </Space>
        </div>
      </Mask>
      <NavBar onBack={() => navigate(-1)}>咨询服务申请</NavBar>
      <Form
        onFinish={onFinish}
        footer={
          <Button disabled={!selectCom} color="primary" type="submit" block>
            立即咨询
          </Button>
        }
      >
        <Form.Item label="咨询服务" disabled>
          <div className={styles.meta}>
            <img
              className={styles.thumb}
              src={empowermentInfo && empowermentInfo.img_url}
              alt="服务封面图"
            />
            <div>
              <div style={{ fontSize: ".875rem" }}>
                服务代码：{empowermentId}
              </div>
              <div>{empowermentInfo && empowermentInfo.title}</div>
            </div>
          </div>
        </Form.Item>
        <Form.Item
          label="公司"
          onClick={() => setPickerVisible(true)}
          clickable
          disabled={!empowermentInfo}
        >
          {selectCom ? selectCom : "请选择"}
          <Picker
            columns={[columns]}
            visible={pickerVisible}
            onClose={() => setPickerVisible(false)}
            onConfirm={(value) => onConfirm(value)}
          />
        </Form.Item>
        <Form.Item
          label="联系人"
          name="name"
          rules={[
            {
              required: true,
              message: "请求填写联系人!",
              validateTrigger: "onChange",
            },
          ]}
        >
          <Input placeholder="请输入联系人姓名" />
        </Form.Item>
        <Form.Item
          label="手机"
          name="tel"
          rules={[
            { required: true, message: "手机号码不能为空" },
            {
              pattern: /^1[3|415178][0-9]{9}$/, // 使用正则表达式验证手机号码格式
              message: "手机号码格式不正确",
            },
          ]}
        >
          <Input placeholder="请输入联系人手机" />
        </Form.Item>
        {goodInfo && (
          <Form.Item>
            <div className={styles.meta}>
              <img
                className={styles.thumb}
                style={{ border: "1px solid #ccc" }}
                src={`https://biaoxq-oss.oss-cn-beijing.aliyuncs.com/${goodInfo.request_no}.jpg`}
                alt={goodInfo.mark_name}
              />
              <div>
                <div style={{ fontSize: ".875rem" }}>{goodInfo.mark_name}</div>
                <div>
                  {"第" +
                    goodInfo.good_kind +
                    "类-" +
                    clazzes[goodInfo.good_kind - 1].title}
                </div>
              </div>
            </div>
          </Form.Item>
        )}
        <Form.Item label="职务" name="duties">
          <Input placeholder="请输入联系人职务" />
        </Form.Item>
        <Form.Item label="留言" name="message">
          <TextArea rows={5} placeholder="请输入你想了解、咨询的任何问题" />
        </Form.Item>
      </Form>
      <List header="咨询顾问">
        <List.Item
          prefix={<Avatar src="/assets/consultant/雷先生.jpg" />}
          title="高级咨询顾问"
        >
          雷先生
        </List.Item>
        <List.Item prefix={<MobileOutlined />} clickable>
          联系电话：186-0000-0000
        </List.Item>
        <List.Item
          prefix={<QrcodeOutlined />}
          onClick={() => {
            Modal.alert({
              content: (
                <img
                  className={styles.qrcode}
                  src="/assets/consultant/qrcode-雷先生.jpg"
                  alt="企业微信二维码"
                />
              ),
              onConfirm: () => {},
            });
          }}
          clickable
        >
          企业微信二维码
        </List.Item>
      </List>
      <div className={styles.footer}>~ 到底了 ~</div>
    </>
  );
}
