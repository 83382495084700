import {
  NavBar,
  List,
  Image,
  AutoCenter,
  Space,
  Tag,
  Footer,
  NoticeBar,
  Mask,
  SpinLoading,
} from "antd-mobile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { reqRiskLists, reqTrademarkInfo } from "../../../service/me/monitoring";
import { clazzes } from "../../../intlClass";

import { useEffect, useState } from "react";

export default function Trademark() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const [visible, setVisible] = useState(true);
  const [riskTm, setRiskTm] = useState([]);
  const [tmInfo, setTmInfo] = useState({});
  useEffect(() => {
    if (params.id) {
      const ptIndex = Number.parseInt(search.split("?").pop() - 1);
      reqRiskLists(params.id, "risk_trademark").then((res) => {
        if (res.data.length > 0) {
          const arr = JSON.parse(res.data[0].risk_trademark);
          if (isNaN(ptIndex) || ptIndex > arr.length) {
            setVisible(false);
            return;
          }
          const result = arr[ptIndex];
          setRiskTm(result);

          result.reg_number &&
            reqTrademarkInfo(result.reg_number).then((res) => {
              setTmInfo(res.data.length > 0 ? res.data[0] : {});
              setVisible(false);
            });
        }
      });
    }
  }, [params.id, search]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>商标监测</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <NoticeBar color="alert" content="该商标状态存在风险，请及时处理" />
      <List header="基本信息">
        <List.Item title="商标">
          <AutoCenter>
            <Image src={riskTm.image_url} width="8rem" fit="contain" />
          </AutoCenter>
        </List.Item>
        <List.Item title="风险">
          <Tag color="danger">待续展</Tag>
        </List.Item>
        <List.Item title="商标名称">{riskTm.name}</List.Item>
        <List.Item title="注册号">{riskTm.reg_number}</List.Item>
        <List.Item title="分类">
          {riskTm.type_num &&
            `第${riskTm.type_num}类-${
              clazzes[Number.parseInt(riskTm.type_num) - 1].title
            }`}
        </List.Item>
        <List.Item title="商标类型">{tmInfo.trademarktype}</List.Item>
        <List.Item title="商标状态">{tmInfo.status}</List.Item>
        <List.Item title="申请时间">{tmInfo.apply_date}</List.Item>
        <List.Item title="申请人">{tmInfo.company}</List.Item>
        <List.Item title="申请人地址">{tmInfo.address_cn}</List.Item>
        <List.Item title="初审公告期号">{tmInfo.first_pubno ?? "-"}</List.Item>
        <List.Item title="初审公告日期">
          {tmInfo.first_pubdate ?? "-"}
        </List.Item>
        <List.Item title="注册公告期号">{tmInfo.reg_pubno ?? "-"}</List.Item>
        <List.Item title="注册公告日期">{tmInfo.reg_pubdate ?? "-"}</List.Item>
        <List.Item title="专用权期限">{tmInfo.period ?? "-"}</List.Item>
        <List.Item title="类似群组/商品/服务">
          <Space style={{ "--gap": ".25rem" }} wrap>
            {tmInfo.products &&
              JSON.parse(tmInfo.products).length > 0 &&
              JSON.parse(tmInfo.products).map((item, index) => (
                <Tag key={index}>
                  {item.num}
                  {item.name}
                </Tag>
              ))}
          </Space>
        </List.Item>
      </List>
      <List header="生命周期">
        {tmInfo.steps &&
          JSON.parse(tmInfo.steps).length > 0 &&
          JSON.parse(tmInfo.steps).map((item, index) => (
            <List.Item key={index} title={item.step}>
              {item.date}
            </List.Item>
          ))}
      </List>
      <Footer style={{ padding: "1rem 0 3rem 0" }} content="~ 到底了 ~" />
    </>
  );
}
