import { Result, Space, Button } from "antd-mobile";
import styles from "./mailbox.module.css";
import { useLocation } from "react-router-dom";
export default function MailBox() {
  const location = useLocation();
  const { search } = location;
  // const pathname = location.state?.referral?.pathname;
  // const parkName = pathname?.split("/").filter(Boolean)[0] ?? "";
  // 跳转邮箱
  const EmailOfficialWebsite = () => {
    const email = search.split("=")[1];
    const domain = email.substring(email.lastIndexOf("@") + 1);
    const emailWebsites = {
      "gmail.com": "https://mail.google.com/",
      "qq.com": "https://mail.qq.com/",
      "163.com": "https://mail.163.com/",
    };
    const WebsiteLink = emailWebsites[domain];

    if (WebsiteLink) {
      window.open(WebsiteLink, "_blank");
    } else {
      const searchLink = `https://www.baidu.com/s?wd=${encodeURIComponent(
        domain + "邮箱官网"
      )}`;
      window.open(searchLink, "_blank");
    }
    return null;
  };

  return (
    <div className={styles.root}>
      <Result
        status="success"
        title="认证邮件已发送"
        description="请前往您的注册邮箱点击邮件中的链接完成邮箱验证。"
      />
      <Space justify="center" block>
        <Button onClick={() => EmailOfficialWebsite()} color="success">
          立刻验证
        </Button>
        {/* <Button onClick={() => navigate(`/${parkName}/home`)} color="success">
          暂不登陆
        </Button> */}
      </Space>
    </div>
  );
}
