import { useLocation, useNavigate } from "react-router-dom";
import { Form, NavBar, Input, Button, Toast } from "antd-mobile";
import { reqResetPasswordForEmail } from "../../service/register";
import { LoginOut } from "../../service/home/login";

export default function Account() {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const parkName = location.pathname.split("/").filter(Boolean)[0];
  const goLogin = () => {
    LoginOut();
    navigate(`/${parkName}/login`);
  };
  // 获取用户信息
  const user = JSON.parse(
    localStorage.getItem("sb-zevgwqoenxkhkvornmdw-auth-token")
  ).user;
  // 密码校验
  const validatePassword = (_, value) => {
    if (!value || form.getFieldValue("newPassword") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("两次输入的密码不一致"));
  };
  const onFinish = (value) => {
    const doPost = async () => {
      await reqResetPasswordForEmail(value.email, value.newPassword).then(
        (res) => {
          if (res.data.user === null) {
            Toast.show({
              icon: "fail",
              content: "新旧密码不能一致",
            });
          } else {
            Toast.show({
              icon: "success",
              content: "修改成功",
              afterClose: goLogin,
            });
          }
        }
      );
    };
    doPost();
  };
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>修改密码</NavBar>
      <Form
        form={form}
        onFinish={onFinish}
        footer={
          <Button type="submit" color="primary" block>
            立即修改
          </Button>
        }
      >
        <Form.Item
          initialValue={user && user.email}
          label="邮箱"
          name="email"
          disabled
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="新密码"
          rules={[
            { required: true, message: "新密码不能为空" },
            { min: 6, message: "密码长度不能小于6位" },
          ]}
        >
          <Input type="password" placeholder="请输入新的密码" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="确认密码"
          rules={[
            { required: true, message: "两次输入的密码不一致" },
            { validator: validatePassword },
          ]}
        >
          <Input type="password" placeholder="请再输入一遍新的密码" />
        </Form.Item>
      </Form>
    </>
  );
}
