import axios from "axios";
class StrapiRequest {
  instance;
  constructor(config) {
    this.instance = axios.create(config);
    this.instance.interceptors.request.use(
      (config) => {
        if (config.headers) {
          config.headers.Authorization =
            "Bearer 4e1e7f28c9ea21fbabbf19aed8f12d53a3673f2457d00b06d2d927be7fe4dd824966c7152d76e4d57271182ce3d2dc4e40678207dcae70ecd4c44c66e7dad81ec330e49ed50828175bd8386799176f0bf9b9d7312760b7b79fc351f01e62fdb172743bb2c002f6823d94a284dde4cf7685d56d8bc02c8f42cb2b2eabe59ac256";
        }
        return config;
      },
      (error) => {
        return error;
      }
    );
    this.instance.interceptors.response.use(
      (res) => {
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  }
  request(config) {
    return new Promise((resolve, reject) => {
      this.instance
        .request(config)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  get(config) {
    return this.instance.request({ ...config, method: "GET" });
  }
  post(config) {
    return this.instance.request({ ...config, method: "POST" });
  }
  put(config) {
    return this.instance.request({ ...config, method: "PUT" });
  }
  delete(config) {
    return this.instance.request({ ...config, method: "DELETE" });
  }
}
export default StrapiRequest;
