import supabase from "..";
export const reqRiskLists = async (comId, riskName) => {
  const { data, count, error } = await supabase
    .from("company")
    .select(riskName, {
      count: "exact",
    })
    .eq("id", comId)
    .neq(riskName, "[]");

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 根据专利申请号查专利信息
export const reqPatentInfo = async (requestNum) => {
  const { data, count, error } = await supabase
    .from("patent")
    .select("*", {
      count: "exact",
    })
    .eq("request_num", requestNum);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
// 注册号查商标
export const reqTrademarkInfo = async (regNum) => {
  const { data, count, error } = await supabase
    .from("trademark")
    .select("*", {
      count: "exact",
    })
    .eq("reg_number", regNum);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
