import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Badge, List, NavBar, Tabs, Tag, Mask, SpinLoading } from "antd-mobile";
import styles from "./index.module.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context";
import { reqOrders, reqFilterOrders } from "../../../service/me";
import { range } from "lodash";

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const params = useParams();
  const parkName = pathname.split("/").filter(Boolean)[0];
  const { userId, parkId } = useContext(UserContext);
  const [visible, setVisible] = useState(true);

  const [filterOrders, setFilterOrders] = useState([]);
  const [navBarIndex, setNavBarIndex] = useState(params.id ?? "1");

  const fetchFilter = (status) => {
    reqFilterOrders(userId, parkId, status).then((res) => {
      setFilterOrders(res.data);
      setVisible(false);
    });
  };
  const titles = ["全部", "待受理", "已受理", "已完成", "已取消"];

  const filterOrder = (Index) => {
    setVisible(true);
    switch (Index) {
      case "1":
        reqOrders(userId, parkId).then((res) => {
          setFilterOrders(res.data);
          setVisible(false);
        });
        break;
      case "2":
        fetchFilter(0);
        break;
      case "3":
        fetchFilter(1);
        break;
      case "4":
        fetchFilter(2);
        break;
      case "5":
        fetchFilter(3);
        break;
      default:
        return;
    }
  };

  const tagColor = (status) => {
    switch (status) {
      case 0:
        return <Tag color="warning">待受理</Tag>;
      case 1:
        return <Tag color="success">已受理</Tag>;
      case 2:
        return <Tag>已完成</Tag>;
      case 3:
        return <Tag fill="outline">已取消</Tag>;
      default:
        return;
    }
  };

  const onChange = (val) => {
    setNavBarIndex(val);
    const result =
      pathname.slice(0, pathname.lastIndexOf("/")) + "/" + val + search;
    navigate(result, { replace: true });
    filterOrder(val);
    return;
  };
  const RenderListItem = (item, index) => {
    return (
      <List.Item
        key={index}
        prefix={
          <Badge content={null} style={{ "--top": "1rem" }}>
            <img
              className={styles.icon}
              src={item.brand_empowerment.img_url}
              alt="封面"
            />
          </Badge>
        }
        extra={tagColor(item.status)}
        description="2023-6-23"
        onClick={() => navigate(`/${parkName}/me/consulting/detail/${item.id}`)}
        clickable
      >
        {item.brand_empowerment.title}
      </List.Item>
    );
  };
  useEffect(() => {
    if (parkId && userId) {
      setNavBarIndex(params.id);
      filterOrder(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, userId, params.id]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>咨询记录</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <Tabs onChange={onChange} className={styles.tabs} activeKey={navBarIndex}>
        {range(5).map((item, index) => (
          <Tabs.Tab title={titles[index]} key={index + 1}>
            <List>
              {filterOrders.length > 0 &&
                filterOrders.map((item, index) => RenderListItem(item, index))}
            </List>
          </Tabs.Tab>
        ))}
      </Tabs>
    </>
  );
}
