export const clazzes = [
  { id: 1, title: "化学原料" },
  { id: 2, title: "颜料油漆" },
  { id: 3, title: "日化用品" },
  { id: 4, title: "燃料油脂" },
  { id: 5, title: "医药用品" },
  { id: 6, title: "金属材料" },
  { id: 7, title: "机械设备" },
  { id: 8, title: "手工器械" },
  { id: 9, title: "科学仪器" },
  { id: 10, title: "医疗器械" },
  { id: 11, title: "灯具空调" },
  { id: 12, title: "运输工具" },
  { id: 13, title: "军火烟火" },
  { id: 14, title: "珠宝钟表" },
  { id: 15, title: "乐器用品" },
  { id: 16, title: "办公用品" },
  { id: 17, title: "橡胶制品" },
  { id: 18, title: "皮革皮具" },
  { id: 19, title: "建筑材料" },
  { id: 20, title: "家具用品" },
  { id: 21, title: "厨房洁具" },
  { id: 22, title: "绳网袋蓬" },
  { id: 23, title: "纱线毛丝" },
  { id: 24, title: "布料床单" },
  { id: 25, title: "服装鞋帽" },
  { id: 26, title: "钮扣拉链" },
  { id: 27, title: "地毯席垫" },
  { id: 28, title: "健身器材" },
  { id: 29, title: "食用制品" },
  { id: 30, title: "方便食品" },
  { id: 31, title: "农林生鲜" },
  { id: 32, title: "啤酒饮料" },
  { id: 33, title: "酒精饮料" },
  { id: 34, title: "烟草烟具" },
  { id: 35, title: "广告销售" },
  { id: 36, title: "金融物管" },
  { id: 37, title: "建筑修理" },
  { id: 38, title: "通讯服务" },
  { id: 39, title: "运输贮藏" },
  { id: 40, title: "材料加工" },
  { id: 41, title: "教育娱乐" },
  { id: 42, title: "科技服务" },
  { id: 43, title: "餐饮住宿" },
  { id: 44, title: "医疗园艺" },
  { id: 45, title: "社会服务" },
];

export const characterNum = [
  { id: 1, number: "1个" },
  { id: 2, number: "2个" },
  { id: 3, number: "3个" },
  { id: 4, number: "4个" },
  { id: 5, number: "5个" },
  { id: 6, number: "6个及以上" },
];
export const activity_status = [
  {
    id: 1,
    title: "未开始",
  },
  {
    id: 2,
    title: "进行中",
  },
  {
    id: 3,
    title: "已结束",
  },
];
export const trademarkStyle = [
  { id: 1, trademark: "中文" },
  { id: 2, trademark: "英文" },
  { id: 3, trademark: "数字" },
  { id: 4, trademark: "图形" },
];
