// import { Mask, Result } from "antd-mobile";
import styles from "./forehead.module.css";
import { useLocation } from "react-router-dom";
import { reqParks } from "../service/home/login";

import { useEffect, useState } from "react";
import MySpin from "./MySpin";
import { STATUS } from "../constants";
export default function Forehead() {
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState(STATUS.idle);
  const location = useLocation();

  const [gotos, setGoto] = useState(STATUS.idle);

  useEffect(() => {
    reqParks().then((res) => {
      if (gotos === STATUS.idle) {
        try {
          res.data.forEach((item) => {
            if (
              item.org_url_pk === window.location.hostname &&
              window.location.pathname === "/"
            ) {
              if (item.org_url_pk === "pk.biaoxq.com") {
                setStatus(STATUS.success);
                throw new Error();
              }
              window.location.pathname = `/${item.short_name}/home`;
            } else if (window.location.hostname === "localhost") {
              setStatus(STATUS.success);
            }
            setGoto(STATUS.success);
          });
        } catch (error) {}
      }
    });
  }, [location, gotos]);
  // if (status === STATUS.success) {
  //   return (
  //     <Mask
  //       color="#FFFFFF"
  //       style={{ position: "relative", minHeight: "100vh" }}
  //     >
  //       <div className={styles.mask}>
  //         <Result
  //           status="warning"
  //           title="请从园区端进入"
  //           description="您输入的地址有误，请联系相关人员。"
  //         />
  //       </div>
  //     </Mask>
  //   );
  // }
  return <div className={styles.root}>{<MySpin />}</div>;
}
