import { NavBar } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

export default function AboutUs() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>关于我们</NavBar>
    </>
  );
}