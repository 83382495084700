import { NavBar, List, Footer, Mask, SpinLoading } from "antd-mobile";
import styles from "./index.module.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../context";
import { reqInfo } from "../../../service/me/property";

export default function Patent() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { parkId } = useContext(UserContext);
  const [ptInfo, setPtInfo] = useState({});
  const [visible, setVisible] = useState(true);
  const filterType = (typeName) => {
    switch (typeName) {
      case "中国实用新型专利":
        return "/assets/icon/ico-patent-1.png";
      case "中国发明专利":
        return "/assets/icon/ico-patent-2.png";
      case "中国外观设计专利":
        return "/assets/icon/ico-patent-3.png";
      default:
        return;
    }
  };
  useEffect(() => {
    if (parkId) {
      const id = pathname.split("/").pop();
      reqInfo(parkId, id, "park_patent", "patent_id", "patent").then((res) => {
        if (res.data.length > 0) {
          setPtInfo(res.data[0].patent);
        }
        setVisible(false);
      });
    }
  }, [pathname, parkId]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>专利</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <List header="基本信息">
        <List.Item title="名称">{ptInfo.patent_name}</List.Item>
        <List.Item
          title="类型"
          prefix={
            <img
              className={styles.icon}
              src={filterType(ptInfo.type_name)}
              alt="专利类型"
            />
          }
        >
          {ptInfo.type_name}
        </List.Item>
        <List.Item title="法律状态">{ptInfo.last_status}</List.Item>
        <List.Item title="申请日期">{ptInfo.request_date ?? "-"}</List.Item>
        <List.Item title="申请号">{ptInfo.request_num ?? "-"}</List.Item>
        <List.Item title="授权公告号">{ptInfo.outhor_num ?? "-"}</List.Item>
        <List.Item title="授权公告时间">{ptInfo.outhor_date ?? "-"}</List.Item>
        <List.Item title="申请（专利权）人">
          {ptInfo.patent_person ?? "-"}
        </List.Item>
        <List.Item title="发明人 / 设计人">{ptInfo.designer ?? "-"}</List.Item>
        <List.Item title="IPC/LOC分类">
          <ul className={styles.list}>
            {ptInfo.category_num &&
              ptInfo.category_num
                .split(";")
                .map((item) => <li key={item}>{item}</li>)}
          </ul>
        </List.Item>
      </List>
      <List header="摘要">
        <List.Item>{ptInfo.brief ?? "-"}</List.Item>
      </List>
      <List header="附图">
        <List.Item>
          <Link to={ptInfo.annex}>专利证明.pdf</Link>
        </List.Item>
      </List>
      <Footer style={{ padding: "1rem 0 3rem 0" }} content="~ 到底了 ~" />
    </>
  );
}
