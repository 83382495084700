import supabase from "..";

// 获取通知列表
export const getNoticeList = async (com_id, parkId) => {
  try {
    // 1. 通过 com_id 查询 member 表，获取对应的 member_id
    const { data: memberData, error: memberError } = await supabase
      .from("member")
      .select("id")
      .eq("company_id", com_id)
      .eq("park_id", parkId)
      .single(); // 一个 com_id 对应一个 member
    
    if (memberError) {
      console.error("获取 member 数据失败:", memberError.message);
    }

    if (!memberData) {
      console.warn("没有找到对应的 会员ID 数据");
      return { data: [] };
    }

    const memberId = memberData.id;

    // 2. 通过 member_id 查询 member_broadcast 表，获取所有的 broadcast_id 和 is_read 字段
    const { data: broadcastData, error: broadcastError } = await supabase
      .from("member_broadcast")
      .select("id, member_id, broadcast_id, is_read")
      .eq("member_id", memberId);
      
    if (broadcastError) {
      console.error("获取通知数据失败:", broadcastError.message);
    }

    if (!broadcastData.length) {
      console.warn("没有找到对应的 通知 数据");
      return { data: [] };
    }

    // 3. 获取所有的 broadcast_id 和 is_read 字段
    const broadcastIds = broadcastData.map(item => item.broadcast_id);
    const broadcastMap = broadcastData.reduce((map, item) => {
      map[item.broadcast_id] = {
        id: item.id,
        member_id: item.member_id,
        is_read: item.is_read,
      };
      return map;
    }, {});

    // 4. 通过 broadcast_id 查询 park_broadcast 表，获取所有通知数据
    const { data: noticeData, count, error: noticeError } = await supabase
      .from("park_broadcast")
      .select("*", { count: "exact" })
      .in("id", broadcastIds) // 使用 .in() 方法查询多个 ID
      .eq("status", "1") // 只查询状态为 1(发布) 的通知

    if (noticeError) {
      throw new Error("查询 通知 表失败", noticeError.message);
    }

    // 5. 合并通知数据与 member_broadcast 数据
    const noticesWithDetails = noticeData.map(notice => ({
      ...notice,
      member_broadcast_id: broadcastMap[notice.id]?.id ?? null, // 会员通知关联表ID
      member_id: broadcastMap[notice.id]?.member_id ?? null, // 会员ID
      is_read: broadcastMap[notice.id]?.is_read, // 是否已读
      broadcast_id: notice.id, // 通知ID
    }));

    return {
      data: noticesWithDetails ?? [],
      count: count ?? 0,
    };
  } catch (error) {
    console.error("获取通知列表数据失败", error);
    return {
      data: [],
      count: 0,
    };
  }
};

// 设置通知已读状态
export const markAsRead = async (noticeId) => {
  const { data, error } = await supabase
    .from("member_broadcast")
    .update({
      is_read: 1,
      update_time: new Date().toISOString() // 将当前时间设置为 update_time 的值
    })
    .eq("id", noticeId);

  if (error) {
    throw new Error("更新通知已读状态失败");
  }

  return data;
}
