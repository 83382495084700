import { BASE_URL, TIME_OUT, STRAPI_URL } from "./config";
import SHRequest from "./request";
import StrapiRequest from "./request/strapiRequest";
export const shRequest = new SHRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
});
export const strapiRequest = new StrapiRequest({
  baseURL: STRAPI_URL,
  timeout: TIME_OUT,
});
