import { NavBar, Tabs } from "antd-mobile";
import styles from "./index.module.css";
import Section from "../../components/Section";
import { clazzes } from "../../intlClass";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TmgoodsList from "./tmgoodsList";
import BottomBar from "../../components/BottomBar";
export default function Transaction() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const onChange = (key) => {
    const result = pathname.slice(0, pathname.lastIndexOf("/")) + "/" + key;
    navigate(result, { replace: true });

    return;
  };
  return (
    <>
      <div className={styles.root}>
        <NavBar backArrow={false}>可交易商标列表</NavBar>
        <Section>
          <Tabs activeKey={params.id} onChange={onChange}>
            {clazzes.map((item, index) => (
              <Tabs.Tab title={item.title} key={item.id}>
                <TmgoodsList good_kind={params.id} />
              </Tabs.Tab>
            ))}
          </Tabs>
        </Section>
      </div>
      <BottomBar />
    </>
  );
}
