import { NavBar, List, Tag, SpinLoading, Mask, Image } from "antd-mobile";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChatBubble from "../../../components/ChatBubble";
import styles from "./index.module.css";
import ChatInput from "../../../components/ChatInput";
import {
  reqFilterOrderInfo,
  reqComments,
  reqParkName,
} from "../../../service/me";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context";
import dayjs from "dayjs";
import { clazzes } from "../../../intlClass";
import { reqTmgoodInfo } from "../../../service/transaction";

export default function Details() {
  const params = useParams();
  const { userId, parkId } = useContext(UserContext);
  const [visible, setVisible] = useState(true);
  const [comments, setComments] = useState([]);
  const [ParkName, setParkName] = useState("");
  const [orderInfo, setOrderInfo] = useState({});
  const [goodInfo, setGoodInfo] = useState();
  const { parkName } = useContext(UserContext);

  const tagColor = (status) => {
    switch (status) {
      case 0:
        return <Tag color="warning">待受理</Tag>;
      case 1:
        return <Tag color="success">已受理</Tag>;
      case 2:
        return <Tag>已完成</Tag>;
      case 3:
        return <Tag fill="outline">已取消</Tag>;
      default:
        return;
    }
  };
  useEffect(() => {
    if (userId && parkId && params.id) {
      reqFilterOrderInfo(userId, parkId, params.id)
        .then((res) => {
          if (res.data.length > 0) {
            setOrderInfo(res.data[0]);
          }
          return res.data[0];
        })
        .then((res) => {
          if (res.goods_id) {
            reqTmgoodInfo(res.goods_id).then((res) => {
              setGoodInfo(res.data[0]);
            });
          }
        });
      reqParkName(parkId).then((res) => {
        if (res.data.length > 0) {
          setParkName(res.data[0].name);
        }
      });
      reqComments(params.id, parkId).then((res) => {
        setComments(res.data);
        setVisible(false);
      });
    }
  }, [params.id, userId, parkId, visible]);
  const navigate = useNavigate();
  return (
    <div className={styles.root}>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <NavBar onBack={() => navigate(-1)}>记录详情</NavBar>
      <List header="基本信息">
        <List.Item title="咨询单号">
          {orderInfo.id &&
            `HQHT${
              dayjs(orderInfo.created_time).format("YYMMDD") + orderInfo.id
            }`}
        </List.Item>
        <List.Item
          title="咨询业务"
          prefix={
            <img
              className={styles.icon}
              src={
                orderInfo.brand_empowerment &&
                orderInfo.brand_empowerment.img_url
              }
              alt="封面"
            />
          }
        >
          {orderInfo.brand_empowerment && orderInfo.brand_empowerment.title}
        </List.Item>
        <List.Item title="咨询时间">
          {orderInfo.created_time &&
            dayjs(orderInfo.created_time).format("YYYY-MM-DD  HH:mm:ss")}
        </List.Item>
        <List.Item
          title="最后更新时间"
          extra={tagColor(orderInfo.status)}
          clickable
        >
          {orderInfo.created_time &&
            dayjs(orderInfo.created_time).format("YYYY-MM-DD  HH:mm:ss")}
        </List.Item>
      </List>
      {orderInfo.goods_id && goodInfo && (
        <List header="商标信息">
          <List.Item
            title="商标类别"
            prefix={
              <Link
                to={`/${parkName}/transaction/details/${goodInfo.id}?request_no=${goodInfo.request_no}&good_kind=${goodInfo.good_kind}`}
              >
                <Image
                  width={64}
                  style={{ border: "1px solid #ccc", margin: "1rem 0" }}
                  className={styles.feature}
                  src={`https://biaoxq-oss.oss-cn-beijing.aliyuncs.com/${goodInfo.request_no}.jpg`}
                />
              </Link>
            }
          >
            {"第" +
              goodInfo.good_kind +
              "类-" +
              clazzes[goodInfo.good_kind - 1].title}
          </List.Item>
        </List>
      )}
      <List header="咨询人">
        <List.Item title="姓名">{orderInfo.name}</List.Item>
        <List.Item title="手机">{orderInfo.tel}</List.Item>
        <List.Item title="职务">{orderInfo.duties}</List.Item>
        <List.Item title="公司">{orderInfo.company_name}</List.Item>
      </List>

      <List header="沟通记录">
        <List.Item>
          {comments.length > 0 &&
            comments.map((item, index) => (
              <ChatBubble
                key={item.id}
                nickname={item.role_flag === 1 ? orderInfo.name : ParkName}
                content={item.content}
                timestamp={dayjs(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                reverse={item.role_flag}
                attachments={item.order_file}
              />
            ))}
        </List.Item>
      </List>
      <ChatInput orderId={orderInfo && orderInfo.id} setVisible={setVisible} />
    </div>
  );
}
