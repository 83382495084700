import {
  Badge,
  List,
  NavBar,
  Skeleton,
  SpinLoading,
  Swiper,
  Mask,
  Image,
} from "antd-mobile";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BellOutlined,
  HistoryOutlined,
  LockOutlined,
  LogoutOutlined,
  PlusCircleOutlined,
  RightOutlined,
  SearchOutlined,
  SmileOutlined,
  TrademarkOutlined,
  UserAddOutlined,
  WarningOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import BottomBar from "../../components/BottomBar";
import styles from "./index.module.css";
import { reqCompanyLists, reqCompanyIds, reqOrders, reqGetNotice } from "../../service/me";
import { useContext, useEffect, useState } from "react";
import Auth from "../../providerRoute";
import { UserContext } from "../../context";
import { reqMemberList } from "../../service/member/vip/index.";
import { reqNewMeeting } from "../../service/member/activity";

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const { parkId, userId } = useContext(UserContext);
  const parkName = location.pathname.split("/").filter(Boolean)[0];
  const [visible, setVisible] = useState(true);
  const [companiesList, setCompaniesList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [notices, setNotices] = useState([]); // 通知数据
  const [comId, setComId] = useState();
  const [selectCom, setSelectCom] = useState();
  const [currentSwiperIndex, setCurrentSwiperIndex] = useState(0);
  const [MeetingCount, setMeetingCount] = useState(0);
  const { logout, role } = Auth();
  // 点击swiper
  const onClick = (item) => {
    if (item) {
      navigate(`/${parkName}/me/company?com_id=${item.id}`);
      return;
    }
    navigate(`/${parkName}/me/company`);
  };
  // 滑动切换swiper的下标
  const onIndexChange = (index) => {
    if (companiesList.length > 0 && index < companiesList.length) {
      setSelectCom(companiesList[index]);
      setComId(companiesList[index].id);
      setCurrentSwiperIndex(index);
    }
  };

  const goProperty = (index) => {
    navigate(`/${parkName}/me/property/${index}?com_id=${comId}`);
  };
  const goMonitoring = (index) => {
    navigate(`/${parkName}/me/monitoring/${index}?com_id=${comId}`);
  };
  const goResetPassword = () => {
    navigate(`/${parkName}/me/account`);
  };
  const goOut = () => {
    logout();
    localStorage.clear();
    navigate(`/${parkName}/home`);
  };
  useEffect(() => {
    if (userId && parkId) {
      reqCompanyIds(userId).then((res) => {
        // console.log(res, '公司id列表');
        reqCompanyLists(res.data, parkId).then((lists) => {
          // console.log(lists, '公司信息列表');
          reqMemberList(parkId).then((res) => {
            // console.log(res, '当前园区会员信息列表');
            lists.data.forEach((item) => {
              const memberInfo = res.data.find((d) => d.company_id === item.id);
              item.vip = memberInfo ? true : false;
              item.member_id = memberInfo ? memberInfo.id : null;
            });
            setCompaniesList(
              lists.data.sort((a, b) => (b.vip ? 1 : a.vip ? -1 : 0))
            );
            if (lists.data.length > 0) {
              setSelectCom(lists.data[0]);
              setComId(lists.data[0].id);
            }
            setVisible(false);
          });
        });
      });

      reqOrders(userId, parkId).then((res) => {
        setOrderList(res.data);
      });
    }
  }, [userId, location, parkId]);

  useEffect(() => {
    if (selectCom) {
      // 请求当前公司的通知数据
      reqGetNotice(selectCom.id, parkId).then((noticeData) => {
        // console.log("获取通知数据成功:", noticeData);
        setNotices(noticeData.data || []); // 更新通知数据
      }).catch((error) => {
        console.error("获取通知数据失败:", error); // 错误处理
      });
      if (selectCom.member_id) {
        reqNewMeeting(selectCom.member_id).then((res) => {
          setMeetingCount(res);
        });
      }
    }
  }, [selectCom]);

  return (
    <div className={styles.root}>
      <Mask visible={visible} color="white">
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <NavBar backArrow={false}>我的</NavBar>
      <Swiper indicator={() => { }} onIndexChange={onIndexChange}>
        {companiesList.length > 0 &&
          (companiesList.length > 0 ? (
            companiesList.map((item, index) => (
              <Swiper.Item key={index}>
                <div className={styles.card} onClick={() => onClick(item)}>
                  <div className={styles.body}>
                    <div style={{ display: "flex" }}>
                      <div className={styles.title}>{item.format_name}</div>
                      {item.vip && role === 1 && (
                        <Image
                          width={24}
                          style={{ marginLeft: "1rem" }}
                          src="/vip.png"
                        />
                      )}
                    </div>
                    <div className={styles.address}>{item.address}</div>
                  </div>
                  <div className={styles.tail}>
                    <RightOutlined />
                  </div>
                </div>
              </Swiper.Item>
            ))
          ) : (
            <Swiper.Item>
              {/* 骨架屏 */}
              <div className={styles.card}>
                <div className={styles.body}>
                  <Skeleton.Title animated />
                  <Skeleton.Paragraph lineCount={2} animated />
                </div>
                <div className={styles.tail}>
                  <RightOutlined />
                </div>
              </div>
            </Swiper.Item>
          ))}

        <Swiper.Item>
          <div
            className={`${styles.card} ${styles.add}`}
            onClick={() => onClick()}
          >
            <div className={styles.button}>
              <div className={styles.icon}>
                <PlusCircleOutlined />
              </div>
              <div className={styles.label}>添加企业</div>
            </div>
          </div>
        </Swiper.Item>
      </Swiper>

      <List header="知识产权">
        <List.Item>
          <div className={styles.statistics}>
            <div className={styles.item}>
              <section onClick={() => goProperty(1)}>
                <div className={styles.label}>商标数量</div>
                <div className={styles.value}>
                  {selectCom && selectCom.trademarks}
                </div>
              </section>

              <div className={styles.warning} onClick={() => goMonitoring(2)}>
                {selectCom && selectCom.risk_trademark_count > 0
                  ? selectCom.risk_trademark_count + "件待处理"
                  : ""}
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.item}>
              <section onClick={() => goProperty(2)}>
                <div className={styles.label}>专利数量</div>
                <div className={styles.value}>
                  {selectCom && selectCom.patents}
                </div>
              </section>

              <div className={styles.warning} onClick={() => goMonitoring(3)}>
                {selectCom && selectCom.risk_patent_count > 0
                  ? selectCom.risk_patent_count + "件待处理"
                  : ""}
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.item}>
              <section onClick={() => goProperty(3)}>
                <div className={styles.label}>著作权数量</div>
                <div className={styles.value}>
                  {selectCom && selectCom.copyrights}
                </div>
              </section>

              <div className={styles.warning}>&nbsp;</div>
            </div>
            <div className={styles.divider} />
            <div className={styles.item}>
              <section onClick={() => goProperty(4)}>
                <div className={styles.label}>软著数量</div>
                <div className={styles.value}>
                  {selectCom && selectCom.copyrightSofts}
                </div>
              </section>

              <div className={styles.warning}>&nbsp;</div>
            </div>
          </div>
        </List.Item>

        <List.Item
          prefix={<SearchOutlined />}
          onClick={() =>
            navigate(
              `/${parkName}/me/property/1?com_id=${currentSwiperIndex !== companiesList.length
                ? companiesList[currentSwiperIndex].id
                : ""
              }`
            )
          }
        >
          全部知识产权
        </List.Item>
        <List.Item
          prefix={<WarningOutlined />}
          onClick={() =>
            navigate(
              `/${parkName}/me/monitoring/1?com_id=${currentSwiperIndex !== companiesList.length
                ? companiesList[currentSwiperIndex].id
                : ""
              }`
            )
          }
        >
          监测预警
        </List.Item>
        <List.Item
          prefix={<MessageOutlined />}
          onClick={() =>
            navigate(
              `/${parkName}/me/notice/?com_id=${currentSwiperIndex !== companiesList.length
                ? companiesList[currentSwiperIndex].id
                : ""
              }`,
              {
                state: { parkId } // 传递parkId
              }
            )
          }
        >
          <Badge
            content={notices.length > 0 ? Badge.dot : null} // 条件判断是否显示点标记
            style={{ "--right": "-.5rem" }}>
            消息通知
          </Badge>
        </List.Item>
      </List>
      <List header="咨询服务">
        <List.Item>
          <div className={styles.orderStatistics}>
            <div
              className={styles.item}
              onClick={() => navigate(`/${parkName}/me/consulting/2`)}
            >
              <Badge
                content={
                  orderList.filter((item) => item.status === 0).length > 0
                    ? orderList.filter((item) => item.status === 0).length
                    : ""
                }
              >
                <img
                  className={styles.icon}
                  src="/assets/icon/fa-clock.svg"
                  alt="图标"
                />
              </Badge>
              <div className={styles.label}>待受理</div>
            </div>
            <div
              className={styles.item}
              onClick={() => navigate(`/${parkName}/me/consulting/3`)}
            >
              <Badge
                content={
                  orderList.filter((item) => item.status === 1).length > 0
                    ? orderList.filter((item) => item.status === 1).length
                    : ""
                }
              >
                <img
                  className={styles.icon}
                  src="/assets/icon/fa-user-clock.svg"
                  alt="图标"
                />
              </Badge>
              <div className={styles.label}>已受理</div>
            </div>
            <div
              className={styles.item}
              onClick={() => navigate(`/${parkName}/me/consulting/4`)}
            >
              <Badge
                content={
                  orderList.filter((item) => item.status === 2).length > 0
                    ? orderList.filter((item) => item.status === 2).length
                    : ""
                }
              >
                <img
                  className={styles.icon}
                  src="/assets/icon/fa-hexagon-check.svg"
                  alt="图标"
                />
              </Badge>
              <div className={styles.label}>已完成</div>
            </div>
            <div
              className={styles.item}
              onClick={() => navigate(`/${parkName}/me/consulting/5`)}
            >
              <Badge
                content={
                  orderList.filter((item) => item.status === 3).length > 0
                    ? orderList.filter((item) => item.status === 3).length
                    : ""
                }
              >
                <img
                  className={styles.icon}
                  src="/assets/icon/fa-hexagon-xmark.svg"
                  alt="图标"
                />
              </Badge>
              <div className={styles.label}>已取消</div>
            </div>
          </div>
        </List.Item>

        <List.Item
          prefix={<HistoryOutlined />}
          onClick={() => navigate(`/${parkName}/me/consulting/1`)}
        >
          全部咨询记录
        </List.Item>
      </List>
      <List header="品牌金融">
        <List.Item prefix={<TrademarkOutlined />}>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            target="_blank"
            to="https://evaluation.biaoxq.com"
          >
            商标品牌价值评估
          </Link>
        </List.Item>
      </List>
      {
        selectCom && role === 1 && selectCom.vip && (
          <List header="会员">
            <List.Item
              prefix={<BellOutlined />}
              onClick={() =>
                navigate(
                  `/${parkName}/me/member/activity/1?member_id=${currentSwiperIndex !== companiesList.length
                    ? companiesList[currentSwiperIndex].member_id
                    : ""
                  }`
                )
              }
            >
              <Badge
                content={MeetingCount === 0 ? "" : MeetingCount}
                style={{ "--right": "-.5rem" }}
              >
                活动通知
              </Badge>
            </List.Item>
          </List>
        )
      }
      <List>
        {selectCom && role === 1 && !selectCom.vip && (
          <List.Item
            prefix={<UserAddOutlined />}
            onClick={() =>
              navigate(
                `/${parkName}/me/member/vip?com_id=${currentSwiperIndex !== companiesList.length
                  ? companiesList[currentSwiperIndex].id
                  : ""
                }`
              )
            }
          >
            <Badge content={Badge.dot} style={{ "--right": "-.5rem" }}>
              会员认证
            </Badge>
          </List.Item>
        )}
        <List.Item
          prefix={<SmileOutlined />}
          onClick={() => navigate(`/${parkName}/about-us`)}
        >
          关于我们
        </List.Item>
        <List.Item prefix={<LockOutlined />} onClick={() => goResetPassword()}>
          修改密码
        </List.Item>
        <List.Item prefix={<LogoutOutlined />} onClick={() => { }}>
          <div className={styles.danger} onClick={() => goOut()}>
            退出登录
          </div>
        </List.Item>
      </List>
      <div className={styles.footer}>~ 到底了 ~</div>
      <BottomBar />
    </div >
  );
}
