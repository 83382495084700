import {
  NavBar,
  List,
  Image,
  AutoCenter,
  Space,
  Tag,
  Footer,
  Mask,
  SpinLoading,
} from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { reqInfo } from "../../../service/me/property";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { clazzes } from "../../../intlClass";
import { UserContext } from "../../../context";
export default function Trademark() {
  const { pathname } = useLocation();
  const { parkId } = useContext(UserContext);
  const [tmInfo, setTmInfo] = useState({});
  const [visible, setVisible] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (parkId) {
      const id = pathname.split("/").pop();
      reqInfo(parkId, id, "park_trademark", "trademark_id", "trademark").then(
        (res) => {
          if (res.data.length > 0) {
            setTmInfo(res.data[0].trademark);
          }
          setVisible(false);
        }
      );
    }
  }, [pathname, parkId]);
  return (
    <>
      <NavBar onBack={() => navigate(-1)}>商标</NavBar>
      <Mask color="white" visible={visible}>
        <SpinLoading
          style={{ "--size": "48px", margin: "auto", marginTop: "40vh" }}
        ></SpinLoading>
      </Mask>
      <List style={{ "--align-items": "end" }} header="基本信息">
        <List.Item title="商标">
          <AutoCenter>
            <Image src={tmInfo.image_url} width="8rem" fit="contain" />
          </AutoCenter>
        </List.Item>
        <List.Item title="商标名称">{tmInfo.name}</List.Item>
        <List.Item title="分类">
          {tmInfo.type_num && clazzes[tmInfo.type_num].title}
        </List.Item>
        <List.Item title="商标类型">{tmInfo.trademarktype}</List.Item>
        <List.Item title="商标状态">{tmInfo.status}</List.Item>
        <List.Item title="申请时间">{tmInfo.apply_date}</List.Item>
        <List.Item title="申请人">{tmInfo.company}</List.Item>
        <List.Item title="申请人地址">{tmInfo.address_cn}</List.Item>
        <List.Item title="初审公告期号">{tmInfo.first_pubno ?? "-"}</List.Item>
        <List.Item title="初审公告日期">
          {tmInfo.first_pubdate ?? "-"}
        </List.Item>
        <List.Item title="注册公告期号">{tmInfo.reg_pubno ?? "-"}</List.Item>
        <List.Item title="注册公告日期">{tmInfo.reg_pubdate ?? "-"}</List.Item>
        <List.Item title="专用权期限">{tmInfo.period ?? "-"}</List.Item>
        <List.Item title="类似群组/商品/服务">
          <Space style={{ "--gap": ".25rem" }} wrap>
            {tmInfo.products &&
              JSON.parse(tmInfo.products).length > 0 &&
              JSON.parse(tmInfo.products).map((item, index) => (
                <Tag key={index}>
                  {item.num}
                  {item.name}
                </Tag>
              ))}
          </Space>
        </List.Item>
      </List>
      <List header="生命周期">
        {tmInfo.steps &&
          JSON.parse(tmInfo.steps).length > 0 &&
          JSON.parse(tmInfo.steps).map((item, index) => (
            <List.Item key={index} title={item.step}>
              {item.date}
            </List.Item>
          ))}
      </List>
      <Footer style={{ padding: "1rem 0 3rem 0" }} content="~ 到底了 ~" />
    </>
  );
}
