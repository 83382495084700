import { useNavigate, useLocation } from "react-router-dom";
import { TabBar } from "antd-mobile";
import {
  HomeOutlined,
  GlobalOutlined,
  AppstoreOutlined,
  UserOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import { findIndex } from "lodash";
import styles from "./BottomBar.module.css";

export default function BottomBar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const parkName = pathname.split("/").filter(Boolean)[0];

  const items = [
    {
      key: `/${parkName}/home`,
      title: "首页",
      icon: <HomeOutlined />,
    },
    {
      key: `/${parkName}/news/1`,
      title: "环球视角",
      icon: <GlobalOutlined />,
    },
    {
      key: `/${parkName}/transaction/1`,
      title: "品牌交易",
      icon: <TransactionOutlined />,
    },
    {
      key: `/${parkName}/empowerment`,
      title: "品牌服务",
      icon: <AppstoreOutlined />,
    },
    {
      key: `/${parkName}/me`,
      title: "我的公司",
      icon: <UserOutlined />,
    },
  ];

  const activeKeyIndex = findIndex(items, (item) =>
    pathname.startsWith(item.key)
  );
  const activeKey = activeKeyIndex > 0 ? items[activeKeyIndex].key : undefined;

  return (
    <div className={styles.root}>
      <TabBar activeKey={activeKey} onChange={(key) => navigate(key)} safeArea>
        {items.map((item) => (
          <TabBar.Item {...item} />
        ))}
      </TabBar>
    </div>
  );
}
